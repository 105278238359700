import { createMuiTheme } from '@material-ui/core';
import { CSSProperties } from 'react';

const getBreakpoint = createMuiTheme().breakpoints;

export const NAVBAR_HEIGHT = 148;

export const SIDEBAR_WIDTH = 280;

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    customColors: {
      aliceBlue: CSSProperties['color'];
      checkboxColor: CSSProperties['color'];
      solitude: CSSProperties['color'];
      loginGradientInit: CSSProperties['color'];
      loginGradientEnd: CSSProperties['color'];
      underlineInputColor: CSSProperties['color'];
      goBackButtonColor: CSSProperties['color'];
      buttonColor: CSSProperties['color'];
      sidebarHeaderBackground: CSSProperties['color'];
      errorMessageColor: CSSProperties['color'];
      hitGray: CSSProperties['color'];
      astral: CSSProperties['color'];
      biscay: CSSProperties['color'];
      geyser: CSSProperties['color'];
      deYork: CSSProperties['color'];
      chileanFire: CSSProperties['color'];
      redRibbon: CSSProperties['color'];
      blackForTexts: CSSProperties['color'];
      placeholderTextGray: CSSProperties['color'];
      darkGrayTextColor?: CSSProperties['color'];
      lightGrayBackgroundColor?: CSSProperties['color'];
      modalBackgroundSuccess: CSSProperties['color'];
      modalBackgroundError: CSSProperties['color'];
      modalTextSuccess: CSSProperties['color'];
      modalTextError: CSSProperties['color'];
      modalGradientSuccessColor: CSSProperties['color'];
      modalGradientErrorColor: CSSProperties['color'];
      successBackground?: CSSProperties['color'];
      successText?: CSSProperties['color'];
      dangerBackground?: CSSProperties['color'];
      dangerText?: CSSProperties['color'];
      stratos?: CSSProperties['color'];
      white?: CSSProperties['color'];
      grayForIcons?: CSSProperties['color'];
      muiErrorColor?: CSSProperties['color'];
      seagul: CSSProperties['color'];
      InvoicePdfUploaderBg: CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    customColors?: {
      aliceBlue?: CSSProperties['color'];
      checkboxColor?: CSSProperties['color'];
      solitude: CSSProperties['color'];
      loginGradientInit?: CSSProperties['color'];
      loginGradientEnd?: CSSProperties['color'];
      underlineInputColor?: CSSProperties['color'];
      goBackButtonColor?: CSSProperties['color'];
      buttonColor?: CSSProperties['color'];
      errorMessageColor?: CSSProperties['color'];
      hitGray?: CSSProperties['color'];
      astral?: CSSProperties['color'];
      biscay?: CSSProperties['color'];
      sidebarHeaderBackground?: CSSProperties['color'];
      geyser?: CSSProperties['color'];
      deYork?: CSSProperties['color'];
      chileanFire?: CSSProperties['color'];
      redRibbon?: CSSProperties['color'];
      blackForTexts?: CSSProperties['color'];
      placeholderTextGray?: CSSProperties['color'];
      darkGrayTextColor?: CSSProperties['color'];
      lightGrayBackgroundColor?: CSSProperties['color'];
      modalBackgroundSuccess: CSSProperties['color'];
      modalBackgroundError: CSSProperties['color'];
      modalTextSuccess: CSSProperties['color'];
      modalTextError: CSSProperties['color'];
      modalGradientSuccessColor: CSSProperties['color'];
      modalGradientErrorColor: CSSProperties['color'];
      successBackground?: CSSProperties['color'];
      successText?: CSSProperties['color'];
      dangerBackground?: CSSProperties['color'];
      dangerText?: CSSProperties['color'];
      stratos?: CSSProperties['color'];
      white?: CSSProperties['color'];
      grayForIcons?: CSSProperties['color'];
      muiErrorColor?: CSSProperties['color'];
      seagul?: CSSProperties['color'];
      InvoicePdfUploaderBg: CSSProperties['color'];
    };
  }
}

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: '#17375F',
      dark: '#03234B',
    },
  },
  typography: {
    fontFamily: 'Lato',
    h2: {
      fontSize: '2.2em',
      fontWeight: 700,
      color: '#17375F',
    },
    h3: {
      fontSize: '1.8em',
      fontWeight: 700,
      color: '#17375F',
    },
    h4: {
      fontSize: '1.4em',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.2em',
      fontWeight: 700,
    },
    h6: {
      fontSize: '1em',
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: 24,
      color: '#212B36',
      [getBreakpoint.down('xs')]: {
        fontSize: 20,
      },
      [getBreakpoint.down(170)]: {
        fontSize: 18,
      },
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
      [getBreakpoint.down('xs')]: {
        fontSize: 11,
      },
    },
    overline: {
      textTransform: 'unset',
      fontSize: 11,
    },
    button: { textTransform: 'unset', color: '#00052D' },
  },
  customColors: {
    aliceBlue: '#F0F8FF',
    checkboxColor: '#212B36',
    solitude: '#EAF6FE',
    loginGradientInit: '#62A2CD',
    loginGradientEnd: '#FAFAFA',
    underlineInputColor: '#30709B',
    goBackButtonColor: '#CCD8E0',
    buttonColor: '#B8C4CC',
    errorMessageColor: '#E20240',
    hitGray: '#9AA6AE',
    astral: '#30709B',
    biscay: '#17375F',
    sidebarHeaderBackground: '#F4F6F8',
    geyser: '#CCD8E0',
    deYork: '#6FCF97',
    chileanFire: '#F86D09',
    redRibbon: '#E20240',
    blackForTexts: '#212B36',
    placeholderTextGray: '#919EAB',
    darkGrayTextColor: '#546068',
    lightGrayBackgroundColor: '#F0F0F0',
    modalBackgroundSuccess: '#C8FACD',
    modalBackgroundError: '#FFE7D9',
    modalTextSuccess: '#005249',
    modalTextError: '#B72136',
    modalGradientSuccessColor: '#007b54',
    modalGradientErrorColor: '#b72135',
    successBackground: '#C8FACD',
    successText: '#005249',
    dangerBackground: '#FFE7D9',
    dangerText: '#B72136',
    stratos: '#00052D',
    white: '#fff',
    grayForIcons: '#637381',
    muiErrorColor: '#f44336',
    seagul: '#80C0EB',
    InvoicePdfUploaderBg: '#F6FBFF',
  },
});

export default theme;
