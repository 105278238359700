import clsx from 'clsx';
import { CSSProperties } from 'react';
import { ColumnStates, EnumStatus, EServiceType, StatesServices } from 'models';
import { UserModel } from 'models';
import { InvoiceState, InvoiceType } from 'models/invoices';
import theme from 'styles/theme';
import dayjs from 'dayjs';

/**
 *  hexToRgbA converts hexadecimal code color to rgba or rgb color, to handle opacity without affecting element's children
 *  @param {CSSProperties['color']} hex - hexadecimal code color
 *  @param {number} opacity - rgba alpha param
 *  @returns {string}
 */
export function hexToRgbA(
  hex: CSSProperties['color'],
  opacity?: number
): string {
  if (hex && hex.length === 7) {
    const red = parseInt(hex.slice(1, 3), 16),
      green = parseInt(hex.slice(3, 5), 16),
      blue = parseInt(hex.slice(5, 7), 16);

    if (typeof opacity === 'number' && !isNaN(opacity)) {
      return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    }
    return `rgb(${red}, ${green}, ${blue})`;
  }

  throw new Error(
    `Hex not accepted, typeof ${hex}: ${typeof hex} is not a valid hex`
  );
}

/**
 * getStatusOption returns an option from a given array
 *
 * @param {EnumStatus.Active | EnumStatus.Unblockable | EnumStatus.Inconsistent | EnumStatus.Blocked | EnumStatus.Custom} status
 * @param {string[] | CSSProperties['color'][]} options
 * @returns {string}
 */
export function getStatusOption(
  status: EnumStatus,
  options: string[] | CSSProperties['color'][]
): string {
  return clsx(
    status === EnumStatus.Active && options[0],
    (status === EnumStatus.Unblockable || status === EnumStatus.Inconsistent) &&
      options[1],
    status === EnumStatus.Blocked && options[2],
    status === EnumStatus.Custom && options[3]
  );
}

/**
 * isEmpty checks for empty objects
 *
 * @param {Record<string, unknown>} object
 * @returns {boolean}
 */
export function isEmpty(object: Record<string, unknown>): boolean {
  return Object.keys(object).length === 0;
}
  
  export const getHourByDate = (ISOdate: string): string => {
    return ISOdate?.split(' ')[1]?.slice(0, 5) || '';
  };


  // eslint-disable-next-line 
  export const touringCardColorByDelay = (
    estimatedDateArrived: string, 
    ) => {
      if(!estimatedDateArrived) return "blue";

      const dateNow = dayjs().format().split('T')[1]?.slice(0, 5);
      const estimatedHour = getHourByDate(estimatedDateArrived);
      const dateYellowCard = dayjs(estimatedDateArrived).subtract(30, 'minutes').format().split('T')[1]?.slice(0, 5);
      
      if(dateNow <= estimatedHour && dateNow <= dateYellowCard) return "blue";
      if(dateNow <= estimatedHour && dateNow >= dateYellowCard) return "yellow";
      if(dateNow >= estimatedHour) return "red";
    };

export const mapStateTolabel = (state: ColumnStates | StatesServices): string =>
  ({
    INICIADO: 'Iniciado',
    REASIGNAR: 'Reasignar',
    'EN SERVICIO': 'En servicio',
    'HACIA DESTINO': 'Hacia destino',
    'EN CAMINO': 'En camino',
    ASIGNADO: 'Asignado',
    ACEPTADO: 'Aceptado',
    CANCELADOASISTIDO: 'Cancelado asistido',
    CANCELADOGRUERO: 'Cancelado gruero',
    RECHAZADO: 'Rechazado',
    CERRADO: 'Cerrado',
    CANCELADOSINDISPONIBILIDAD: 'Cancelado - Sin Disponibilidad'
  }[state] ?? state);

export const mapInvoiceType = (typeInvoice: InvoiceType): string =>
  ({
    FACTURA_A: 'Factura A',
    FACTURA_B: 'Factura B',
    FACTURA_C: 'Factura C',
    FACTURA_D: 'Factura D',
  }[typeInvoice] || typeInvoice);

export const stateInvoiceToLabel = (state: InvoiceState): string => {
  const dictionary = {
    RECIBIDA: 'Recibida',
    APROBADA: 'Aprobada',
    ENVIO_DE_PAGO: 'Envio de pago',
    PAGADO: 'Pagado',
    ANULADA: 'Anulada',
  };
  return dictionary[state] || '';
};

export const stateInvoiceToColors = (state: InvoiceState): string => {
  const colorByStateInvoice = {
    RECIBIDA: theme.customColors.chileanFire,
    APROBADA: theme.customColors.astral,
    ENVIO_DE_PAGO: theme.customColors.seagul,
    PAGADO: theme.customColors.deYork,
    ANULADA: theme.customColors.redRibbon,
  };
  return colorByStateInvoice[state] || '';
};

export const mapServiceTypeToLabel = (serviceType: EServiceType): string => {
  const options = {
    [EServiceType.Transport]: 'Traslado',
    [EServiceType.Mechanical]: 'Mecánica',
  };
  return options[serviceType] ?? serviceType;
};

export const isTouringUser = (user: UserModel): boolean => {
  return !!user.rolesUser.find((role) => !role.authority.includes('TOW'));
};

export const mapCostKeyToLabel = (key: string): string => {
  interface LabelDictionary {
    [index: string]: string;
  }

  const dictionary: LabelDictionary = {
    night: 'Horaro nocturno',
    vehicleHeavy: 'Costos para pesado',
    sinous: 'Kilometros en sinuoso',
    mountain: 'Kilometros en montaña',
    gravel: 'Kilometros en ripio',
    excedentKm: 'Excedente de kilometraje',
    initialMove: 'Movida del radio',
    fixedCost: 'Arancel fijo',
  };

  return dictionary[key];
};
