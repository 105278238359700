import { Backdrop, CircularProgress } from '@material-ui/core';

export default function Loader(props: { isOpen?: boolean }): JSX.Element {
  const { isOpen } = props;
  return (
    <Backdrop
      style={{ zIndex: 2001 }}
      open={typeof isOpen === 'boolean' ? isOpen : true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
