import { authActions, TouringClubAction } from 'redux/actions/auth';
import { backOfficeRoles } from 'models/rol';
import { UserModel } from 'models/user/user';
import { TouringApiErrorResponseData } from 'models';

type Authorities = backOfficeRoles[];

export interface AuthState {
  token?: string;
  userData?: Partial<UserModel>;
  authorities?: Authorities;
  error?: TouringApiErrorResponseData;
}

const initialState: AuthState = {
  token: '',
};

export function authenticationReducer(
  state: AuthState = initialState,
  action: TouringClubAction
): AuthState {
  switch (action.type) {
    case authActions.AUTH_FAILED: {
      return { ...state, error: action.payload as TouringApiErrorResponseData };
    }
    case authActions.AUTH_START: {
      return state;
    }
    case authActions.AUTH_SUCCESS: {
      const { userData, authorities, token } = action.payload as {
        userData: Partial<UserModel>;
        authorities: Authorities;
        token: string;
      };
      return { ...state, token, userData, authorities };
    }
    case authActions.LOGOUT_SUCCESS:
      sessionStorage.removeItem('temporalTouringToken');
      sessionStorage.removeItem('userData');

      return {
        ...state,
        token: '',
        userData: undefined,
        authorities: undefined,
      };
    default:
      return state;
  }
}
