import { CSSProperties } from 'react';
import { Chip, ChipProps, makeStyles, Theme } from '@material-ui/core';
import { getStatusOption, hexToRgbA } from 'utils/helperFunctions';
import { EnumStatus } from 'models';

const useStyles = (props: StatusChipProps) =>
  makeStyles((theme: Theme) => ({
    chip: {
      ...theme.typography.overline,
      maxHeight: 22,
      borderRadius: 6,
      backgroundColor: getStatusOption(props.status ?? EnumStatus.Active, [
        hexToRgbA(theme.customColors.deYork, 0.2),
        hexToRgbA(theme.customColors.chileanFire, 0.2),
        hexToRgbA(theme.customColors.redRibbon, 0.2),
        ...(props.customColor ? [hexToRgbA(props.customColor, 0.2)] : []),
      ]),
      '& > span': {
        color: getStatusOption(props.status ?? EnumStatus.Active, [
          theme.customColors.deYork,
          theme.customColors.chileanFire,
          theme.customColors.redRibbon,
          ...(props.customColor ? [props.customColor] : []),
        ]),
        fontWeight: 700,
        opacity: 1,
      },
    },
  }));

export interface StatusChipProps extends ChipProps {
  status?: EnumStatus;
  customMessage?: string;
  customColor?: CSSProperties['color'];
}

export default function StatusChip(props: StatusChipProps): JSX.Element {
  const {
    status = EnumStatus.Active,
    customMessage = '',
    customColor = '',
    ...chipProps
  } = props;
  const classes = useStyles({ status, customColor })();
  const selectStatus = getStatusOption(status, [
    'Activo',
    'Pendiente autorización',
    'Bloqueado',
    customMessage,
  ]);

  return (
    <Chip
      {...chipProps}
      label={selectStatus}
      classes={{ root: classes.chip }}
    />
  );
}
