import { SuggestedTowBaseResponse } from 'models';
import { ServiceAssignmentAction } from 'redux/actions/serviceAssignment';

interface TowServiceRateModalState {
  show: boolean;
  data?: SuggestedTowBaseResponse;
}

const initialState: TowServiceRateModalState = {
  show: false,
};

function towServiceRateModalReducer(
  state: TowServiceRateModalState = initialState,
  action: ServiceAssignmentAction
): TowServiceRateModalState {
  switch (action.type) {
    case 'SHOW_TOW_SERVICE_RATE_MODAL': {
      return {
        ...state,
        show: true,
        data: action.payload,
      };
    }
    case 'HIDE_TOW_SERVICE_RATE_MODAL': {
      return {
        ...state,
        show: false,
        data: undefined,
      };
    }
    default: {
      return state;
    }
  }
}

export type { TowServiceRateModalState };
export { towServiceRateModalReducer };
