import { withStyles, Radio, RadioProps } from '@material-ui/core';

const CustomRadio = withStyles((theme) => ({
  root: {
    color: theme.customColors.placeholderTextGray,
    '&$checked': {
      color: theme.customColors.astral,
    },
  },
  checked: {},
}))((props: RadioProps) => <Radio color="default" {...props} />);

export default CustomRadio;
