import Loader from 'components/loader/Loader';
import React from 'react';

interface ComponentImport {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (): Promise<{ default: React.ComponentType<any> }>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyReturn = React.LazyExoticComponent<React.ComponentType<any>>;

function setForceRefresh(value: boolean): void {
  window.localStorage.setItem(
    'page-has-been-force-refreshed',
    value ? 'true' : 'false'
  );
}

function getForceRefresh(): boolean {
  const value = window.localStorage.getItem('page-has-been-force-refreshed');
  return value ? value === 'true' : false;
}

export default function lazyWithRetry(factory: ComponentImport): LazyReturn {
  return React.lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = getForceRefresh();
    try {
      const component = await factory();

      setForceRefresh(false);

      return component;
    } catch (e) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        setForceRefresh(true);
        window.location.reload();
        return { default: Loader };
      }

      throw e;
    }
  });
}
