export enum ColumnStates {
  INITIATED = 'INICIADO',
  REASIGN = 'REASIGNAR',
  INSERVICE = 'EN SERVICIO',
  TODESTINATION = 'HACIA DESTINO',
  ONWAY = 'EN CAMINO',
  ASIGN = 'ASIGNADO',
  CANCELADOASISTIDO = 'CANCELADOASISTIDO',
  TOWCANCEL = 'CANCELADOGRUERO',
  CLOSED = 'CERRADO',
  ACCEPTED = 'ACEPTADO',
  REJECTED = 'RECHAZADO',
  CANCELADOSINDISPONIBILIDAD = 'CANCELADOSINDISPONIBILIDAD'
}

export interface CurrentServiceColumnModel {
  title: string;
  states: ColumnStates[];
}
