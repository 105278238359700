import { Company, TowBaseModel } from 'models';
import {
  ECompanyRegistrationActions,
  ICompanyRegistrationAction,
} from 'redux/actions/companyRegistration';

interface ICompanyRegistrationState {
  companyId: number | null;
  towBasesList: TowBaseModel[];
  firstStep: Company.InformationFormValues | null;
  secondStep: Company.FleetFormValues[] | [];
  thirdStep: Company.CollaboratorFormValues[] | [];
}

const initialState: ICompanyRegistrationState = {
  companyId: null,
  towBasesList: [],
  firstStep: null,
  secondStep: [],
  thirdStep: [],
};

export default function companyRegistrationReducer(
  state = initialState,
  action: ICompanyRegistrationAction
): ICompanyRegistrationState {
  switch (action.type) {
    case ECompanyRegistrationActions.SET_INITIAL_STATE:
      return {
        ...initialState,
      };
    case ECompanyRegistrationActions.SET_COMPANY_INFORMATION:
      return {
        ...state,
        firstStep: action.payload as Company.InformationFormValues | null,
      };
    case ECompanyRegistrationActions.SET_FLEET:
      return {
        ...state,
        secondStep: action.payload as Company.FleetFormValues[] | [],
      };
    case ECompanyRegistrationActions.SET_COLLABS:
      return {
        ...state,
        thirdStep: action.payload as Company.CollaboratorFormValues[] | [],
      };
    case ECompanyRegistrationActions.SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload as number,
      };
    case ECompanyRegistrationActions.SET_TOW_BASES_LIST:
      return {
        ...state,
        towBasesList: action.payload as TowBaseModel[],
      };
    default:
      return state;
  }
}
