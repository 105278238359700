import { UserModel } from "models";

export interface NoteModel {
  id?: number;
  comment: string;
  time: string;
  private: boolean;
  user?: UserModel;
}

export interface NotesValues {
  comment: string;
  private: boolean;
  time?: string;
}

export enum ENoteType {
  Operator = 'Operador',
  TowCompany = 'Prestador',
}
