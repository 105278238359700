import { SuggestedTowBaseResponse } from 'models';
import { ServiceAssignmentAction } from 'redux/actions/serviceAssignment';

interface SuggestedTowBasesState {
  [Key: number]: {
    values: SuggestedTowBaseResponse[];
    loading: boolean;
    error?: string;
    adding: boolean;
    addingError?: string;
    removing: boolean;
    removingError?: string;
    updating: boolean;
    updatingError?: string;
  };
}

const initialState: SuggestedTowBasesState = {};

function suggestedTowBaseReducer(
  state: SuggestedTowBasesState = initialState,
  action: ServiceAssignmentAction
): SuggestedTowBasesState {
  switch (action.type) {
    case 'LOADING_SUGGESTED_TOW_BASES': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          values: [],
          loading: true,
          error: undefined,
        },
      };
    }
    case 'FETCH_SUGGESTED_TOW_BASES_SUCCESS': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          values: action.payload.suggestedTowBases,
          loading: false,
          error: undefined,
        },
      };
    }
    case 'FETCH_SUGGESTED_TOW_BASES_ERROR': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          loading: false,
          error: action.payload.error,
        },
      };
    }
    case 'LOADING_NEW_SUGGESTED_TOW_BASE': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          adding: true,
          addingError: undefined,
        },
      };
    }
    case 'ADD_SUGGESTED_TOW_BASE_SUCCESS': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          adding: false,
          addingError: undefined,
        },
      };
    }
    case 'ADD_SUGGESTED_TOW_BASE_ERROR': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          adding: false,
          addingError: action.payload.error,
        },
      };
    }
    case 'LOADING_REMOVE_SUGGESTED_TOW_BASE': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          removing: true,
          removingError: undefined,
        },
      };
    }
    case 'REMOVE_SUGGESTED_TOW_BASE_SUCCESS': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          removing: false,
          removingError: undefined,
        },
      };
    }
    case 'REMOVE_SUGGESTED_TOW_BASE_ERROR': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          removing: false,
          removingError: action.payload.error,
        },
      };
    }
    case 'LOADING_UPDATE_SUGGESTED_TOW_BASE': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          updating: true,
          updatingError: undefined,
        },
      };
    }
    case 'UPDATE_SUGGESTED_TOW_BASE_SUCCESS': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          updating: false,
          updatingError: undefined,
        },
      };
    }
    case 'UPDATE_SUGGESTED_TOW_BASE_ERROR': {
      return {
        ...state,
        [action.payload.serviceId]: {
          ...state[action.payload.serviceId],
          updating: false,
          updatingError: action.payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export type { SuggestedTowBasesState };
export { suggestedTowBaseReducer };
