import { Categories } from 'components/coverageQueryDetailsDialog/CoverageQueryDetailsDialog';
import { VehicleModel } from 'models';

export enum EnumStatus {
  Active = 'ACTIVA',
  Unblockable = 'DESBLOQUEABLE',
  Inconsistent = 'INCONSISTENTE',
  Blocked = 'BLOQUEADO',
  Custom = 'CUSTOM',
}

export interface WarningResponse {
  message: string;
}

export interface InsuranceByPlateModel {
  id?: number;
  error: string;
  number: string;
  policyState: EnumStatus.Active | EnumStatus.Unblockable | EnumStatus.Inconsistent | EnumStatus.Blocked;
  policy: string;
  transferApt: boolean;
  serviceProvider: string;
  name: string;
  phone: string;
  annualServices: number;
  monthlyServices: number;
  linearKm: number;
  totalKm: number;
  availableMonth: number;
  availableAnnually: number;
  consumedThisYear: number;
  consumedThisMonth: number;
  vehicle: Partial<VehicleModel>;
  observations: string;
  category: Partial<Categories>;
  producer: string;
  warnings: WarningResponse[];
}

export interface InsuranceByDniPolicyModel {
  error: string;
  branch: string;
  policy: string;
  emissionDate: string;
  validSince: string;
  validUntil: string;
  dni: string;
  insuredName: string;
  brand: string;
  model: string;
  year: string;
  plate: string;
  chasis: string;
  motor: string;
}

export interface InsuranceCompany {
  id: bigint;
  code: string;
  name: string;
  integrationMethod: string;
  active: boolean;
}
