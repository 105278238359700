import { FilterOption } from './types';

export const FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'Nombre',
    value: 'name',
  },
  {
    label: 'Apellido',
    value: 'lastName',
  },
  {
    label: 'Rol',
    value: 'role',
  },
  {
    label: 'DNI',
    value: 'dni',
  },
];
