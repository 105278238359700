import {
  makeStyles,
  Theme,
  Typography,
  Button,
  Dialog,
} from '@material-ui/core';
import { ReactComponent as ErrorIcon } from 'assets/icons/modal-error.svg';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.customColors.modalBackgroundError,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(3, 0),
  },
  title: {
    fontWeight: 'normal',
    color: theme.customColors.modalTextError,
    maxWidth: 312,
  },
  btn: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(5.75),
    minHeight: 48,
    minWidth: 312,
  },
}));

export interface Props {
  open?: boolean;
  title: string;
  btnText: string;
  onConfirm?: () => void;
  onClose?: () => void;
}

export default function ErrorDialog({
  open = false,
  title,
  btnText,
  onConfirm = () => null,
  onClose = () => null,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} onClose={onClose}>
      <div className={classes.iconContainer}>
        <ErrorIcon />
      </div>

      <Typography align="center" variant="h5" className={classes.title}>
        {title}
      </Typography>

      <Button
        variant="contained"
        className={classes.btn}
        size="large"
        onClick={onConfirm}
      >
        {btnText}
      </Button>
    </Dialog>
  );
}
