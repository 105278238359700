import { CurrentServiceModel } from 'models';
import { ServiceAssignmentAction } from 'redux/actions/serviceAssignment';

interface CurrentServiceState {
  value?: CurrentServiceModel;
  loading: boolean;
  error?: string;
}

const initialState: CurrentServiceState = {
  value: undefined,
  loading: false,
};

function currentServiceReducer(
  state: CurrentServiceState = initialState,
  action: ServiceAssignmentAction
): CurrentServiceState {
  switch (action.type) {
    case 'LOADING_CURRENT_SERVICE': {
      return {
        ...state,
        value: undefined,
        loading: true,
      };
    }
    case 'SET_CURRENT_SERVICE': {
      return {
        ...state,
        value: action.payload,
        loading: false,
        error: undefined,
      };
    }
    case 'CURRENT_SERVICE_ERROR': {
      return {
        ...state,
        value: undefined,
        loading: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export type { CurrentServiceState };
export { currentServiceReducer };
