import { ReactNode } from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from 'components/usersManagement/UserCardButton/UserCardButtonStyles';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as CardAccountIcon } from 'assets/icons/card-account.svg';

export enum ButtonType {
  EDIT = 'edit',
  DETAILS = 'details',
  REMOVE = 'remove',
  CHANGE_PASSWORD = 'change-password',
}

const getEndIcon = (type: ButtonType): JSX.Element | null => {
  switch (type) {
    case ButtonType.EDIT:
      return <EditIcon />;
    case ButtonType.REMOVE:
      return <DeleteIcon />;
    default:
      return null;
  }
};

const getStartIcon = (type: ButtonType): JSX.Element | null => {
  if (type === ButtonType.CHANGE_PASSWORD) return <CardAccountIcon />;
  return null;
};

const getButtonVariant = (type: ButtonType, isFilled: boolean) => {
  switch (type) {
    case ButtonType.EDIT:
      return isFilled ? 'contained' : 'outlined';
    case ButtonType.CHANGE_PASSWORD:
      return 'text';
    default:
      return 'outlined';
  }
};

interface Props {
  type?: ButtonType;
  isEditing?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: ReactNode;
}

export default function UserCardButton({
  type = ButtonType.DETAILS,
  isEditing = false,
  disabled = false,
  onClick = () => null,
  children,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Button
      variant={getButtonVariant(type, isEditing)}
      className={clsx(classes.btn, {
        [classes.editBtn]: type === ButtonType.EDIT,
        [classes.isEditing]: type === ButtonType.EDIT && isEditing,
        [classes.deleteBtn]: type === ButtonType.REMOVE,
        [classes.changePasswordBtn]: type === ButtonType.CHANGE_PASSWORD,
      })}
      startIcon={getStartIcon(type)}
      endIcon={getEndIcon(type)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
