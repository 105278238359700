import * as yup from 'yup';

const requiredText = 'Campo vacío';

export const touringUserSchema = yup.object().shape({
  role: yup.string().required(requiredText),
  name: yup.string().required(requiredText),
  lastname: yup.string().required(requiredText),
  email: yup
    .string()
    .email('Debes ingresar un email válido')
    .required(requiredText),
  dni: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Este campo solo acepta números',
      excludeEmptyString: true,
    })
    .min(6, 'Debe contener entre 6 y 8 caracteres')
    .required(requiredText),
  enabled: yup.boolean(),
});

export const companyUserSchema = yup.object().shape({
  role: yup.string().required(requiredText),
  towCompany: yup
    .object()
    .shape({ id: yup.string(), name: yup.string() })
    .required(requiredText),
  name: yup.string().required(requiredText),
  lastname: yup.string().required(requiredText),
  dni: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Este campo solo acepta números',
      excludeEmptyString: true,
    })
    .min(6, 'Debe contener entre 6 y 8 caracteres')
    .required(requiredText),
  driversLicense: yup.string().required(requiredText),
  phone: yup.string().required(requiredText),
  email: yup
    .string()
    .email('Debes ingresar un email válido')
    .required(requiredText),
  enabled: yup.boolean(),
});
