import { TowBaseModel } from 'models';
import { TowBasesByServiceTypeAction } from 'redux/actions/serviceAssignment/towBasesByServiceType';

interface TowBasesByServiceTypeState {
  values: TowBaseModel[];
  loading: boolean;
  error?: string;
}

const initialState: TowBasesByServiceTypeState = {
  values: [],
  loading: false,
  error: undefined,
};

function towBasesByServiceTypeReducer(
  state: TowBasesByServiceTypeState = initialState,
  action: TowBasesByServiceTypeAction
): TowBasesByServiceTypeState {
  switch (action.type) {
    case 'LOADING_TOW_BASES_BY_SERVICE_TYPE': {
      return {
        values: [],
        loading: true,
      };
    }
    case 'FETCH_TOW_BASES_BY_SERVICE_TYPE_SUCCESS': {
      return {
        values: action.payload.towBases,
        loading: false,
      };
    }
    case 'FETCH_TOW_BASES_BY_SERVICE_TYPE_ERROR': {
      return {
        values: [],
        loading: false,
        error: action.payload.error,
      };
    }
    case 'CLEAR_TOW_BASES_BY_SERVICE_TYPE': {
      return {
        values: [],
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}

export type { TowBasesByServiceTypeState };
export { towBasesByServiceTypeReducer };
