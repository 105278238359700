import { touringUserSchema, companyUserSchema } from './validators';

type FormSchema = typeof touringUserSchema | typeof companyUserSchema;

export const getValidationSchema = (
  platform: string
): FormSchema | undefined => {
  if (platform === 'Usuario Touring') return touringUserSchema;
  if (platform === 'Usuario prestador') return companyUserSchema;
};
