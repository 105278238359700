import { ENoteType, NoteModel } from 'models';
import { Action } from 'redux';

export interface NotesAction extends Action<string> {
  payload?: NoteModel | NoteModel[] | null;
  meta?: ENoteType | number | string;
}

export enum ENotesActions {
  ADD_NEW_NOTE = 'ADD_NEW_NOTE',
  CLEAN_STATE = 'CLEAN_STATE',
  LOAD_NOTES = 'LOAD_NOTES',
}

export function addNote(payload: NoteModel, meta: ENoteType): NotesAction {
  return {
    type: ENotesActions.ADD_NEW_NOTE,
    payload,
    meta,
  };
}

export function loadNotes(
  payload: NoteModel[] | null,
  meta?: number | string
): NotesAction {
  return {
    type: ENotesActions.LOAD_NOTES,
    payload,
    meta,
  };
}

export function cleanNotesState(): NotesAction {
  return {
    type: ENotesActions.CLEAN_STATE,
  };
}
