import { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Typography,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import useStyles from './UserManagementFormStyles';
import TouringAutocomplete from 'components/TouringAutocomplete';
import TouringTextField from 'components/TouringTextField';
import TouringRadioBox from 'components/TouringRadioBox/TouringRadioBox';
import UserCardButton, { ButtonType } from '../UserCardButton/UserCardButton';
import { ConfirmationDialog } from 'components/TouringDialogs';

import { defaultInitialValues, mockedPlatforms } from './constants';
import { getValidationSchema } from './helpers';
import { selectUserData, SelectedUser } from 'utils/selectors/usersManagement';

import { AxiosInstanceContext } from 'contexts/AxiosInstanceProvider';
import { apiRoutes } from 'routes/apiRoutes';
import { UserModel, RolModel, TowCompanyModel, TouringApiError } from 'models/';

export interface FormAPI {
  cleanForm: () => void;
}

type SubmitBtnOptions = {
  text: string;
  callback: (values: Partial<SelectedUser>, formAPI: FormAPI) => void;
};

type BtnOptions = {
  text: string;
  callback: () => void;
};

interface Props {
  primaryBtnOptions: SubmitBtnOptions;
  secondaryBtnOptions?: BtnOptions;
  requestPasswordUpdateBtnOptions?: Omit<BtnOptions, 'text'>;
  canToggleEdit?: boolean;
  initialUser?: UserModel;
}

export default function UserManagementForm({
  primaryBtnOptions,
  secondaryBtnOptions,
  requestPasswordUpdateBtnOptions,
  canToggleEdit = false,
  initialUser,
}: Props): JSX.Element {
  const classes = useStyles();

  const [showDiscardConfirm, setShowDiscardConfirm] = useState<boolean>(false);

  const { touringApiService } = useContext(AxiosInstanceContext);
  const [rolesList, setRolesList] = useState<RolModel[]>([]);
  const [filteredRolesList, setFilteredRolesList] = useState<RolModel[]>([]);
  const [companiesList, setCompaniesList] = useState<TowCompanyModel[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(() => !canToggleEdit);

  const [initialValues] = useState<SelectedUser>(() => {
    if (!initialUser) return defaultInitialValues;
    return selectUserData(initialUser);
  });

  const [validationSchema, setValidationSchema] = useState(
    getValidationSchema(
      initialValues?.platform || defaultInitialValues.platform
    )
  );

  const submitForm = (values: SelectedUser) => {
    const hasErrors = Boolean(Object.keys(formik.errors).length);

    if (hasErrors) return;

    const { company, driversLicense, phone, ...basicData } = values;
    const formApi = {
      cleanForm: () => formik?.resetForm(),
    };

    if (values.platform === 'Usuario Touring') {
      primaryBtnOptions.callback(basicData, formApi);
    } else {
      primaryBtnOptions.callback(
        {
          ...basicData,
          company,
          driversLicense,
          phone,
        },
        formApi
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: submitForm,
  });

  const handlePasswordUpdateRequest = () => {
    requestPasswordUpdateBtnOptions?.callback();
  };

  /* ROLES */
  useEffect(() => {
    touringApiService
      .get(apiRoutes.ROLES.getAll)
      .then(({ data }: { data: RolModel[] }) => setRolesList(data))
      .catch((err: TouringApiError) => console.error(err));
  }, []);

  useEffect(() => {
    let filteredRoles = [];

    if (formik.values.platform === 'Usuario Touring') {
      filteredRoles = rolesList.filter(
        (role: RolModel) => !role.authority?.includes('TOW')
      );
    } else {
      filteredRoles = rolesList.filter((role: RolModel) =>
        role.authority?.includes('TOW')
      );
    }

    setFilteredRolesList(filteredRoles);
  }, [rolesList, formik.values.platform]);

  /* Tow Companies */
  useEffect(() => {
    touringApiService
      .get(apiRoutes.TOW_COMPANIES.getAll)
      .then(({ data }: { data: TowCompanyModel[] }) => {
        setCompaniesList(data);
      })
      .catch((err: TouringApiError) => console.error(err));
  }, []);

  useEffect(() => {
    formik.setErrors({});
    setValidationSchema(getValidationSchema(formik.values.platform));
  }, [formik.values.platform]);


  return (
    <>
      <ConfirmationDialog
        open={showDiscardConfirm}
        title="Deshacer cambios"
        description="Si deshaces lo cambios deberás llenar los campos nuevamente"
        onConfirmOptions={{
          text: 'Regresar',
          action: () => setShowDiscardConfirm(false),
        }}
        onCancelOptions={{
          text: 'Deshacer',
          action: () => {
            formik.resetForm();
            setShowDiscardConfirm(false);
          },
        }}
      />

      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="space-between">
          <Typography classes={{ root: classes.sectionTitle }} variant="h3">
            Datos y rol de usuario
          </Typography>
          <div>
            {canToggleEdit && (
              <UserCardButton
                type={ButtonType.EDIT}
                isEditing={isEditing}
                onClick={() => setIsEditing((prevEditing) => !prevEditing)}
              >
                Editar
              </UserCardButton>
            )}
          </div>
        </Grid>

        <div className={classes.formGrid}>
          <TouringTextField
            onBlur={formik.handleBlur}
            select
            fullWidth
            variant="outlined"
            size="small"
            label="Plataforma"
            name="platform"
            disabled={!isEditing}
            value={formik.values.platform}
            onChange={formik.handleChange}
          >
            {mockedPlatforms.map((platform: string) => (
              <MenuItem value={platform} key={platform}>
                <Typography
                  className={classes.selectOption}
                  align="left"
                  noWrap
                >
                  {platform.toLowerCase()}
                </Typography>
              </MenuItem>
            ))}
          </TouringTextField>

          <TouringTextField
            onBlur={formik.handleBlur}
            select
            fullWidth
            variant="outlined"
            size="small"
            label="Rol"
            name="role"
            disabled={!isEditing}
            value={formik.values.role}
            onChange={formik.handleChange}
            error={!!formik.touched.role && !!formik.errors.role}
            helperText={!!formik.touched.role && formik.errors.role}
          >
            {filteredRolesList.length === 0 && initialValues && (
              <MenuItem value={initialValues.role}></MenuItem>
            )}
            {filteredRolesList.map((role: RolModel) => (
              <MenuItem value={`${role.id}`} key={role.id}>
                <Typography
                  className={classes.selectOption}
                  align="left"
                  noWrap
                >
                  {role.description.toLowerCase()}
                </Typography>
              </MenuItem>
            ))}
          </TouringTextField>

          {formik.values.platform === 'Usuario prestador' && (
            <TouringAutocomplete
              disabled={!isEditing}
              value={formik.values.company}
              onChange={(_, { id, name }: TowCompanyModel) =>
                formik.setFieldValue('company', { id, name })
              }
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              options={companiesList}
              textFieldProps={{
                size: 'small',
                label: 'Empresa',
                name: 'company',
              }}
            />
          )}

          <div className={classes.dividedFormField}>
            <TouringTextField
              onBlur={formik.handleBlur}
              label="Nombre"
              name="name"
              size="small"
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={!isEditing}
              error={!!formik.touched.name && !!formik.errors.name}
              helperText={!!formik.touched.name && formik.errors.name}
            />
            <TouringTextField
              onBlur={formik.handleBlur}
              label="Apellido"
              name="lastname"
              size="small"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              disabled={!isEditing}
              error={!!formik.touched.lastname && !!formik.errors.lastname}
              helperText={!!formik.touched.lastname && formik.errors.lastname}
            />
          </div>
          {formik.values.platform === 'Usuario Touring' && (
            <TouringTextField
              onBlur={formik.handleBlur}
              label="Correo electónico"
              name="email"
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
              disabled={!isEditing}
              error={!!formik.touched.email && !!formik.errors.email}
              helperText={!!formik.touched.email && formik.errors.email}
            />
          )}
          <TouringTextField
            onBlur={formik.handleBlur}
            inputProps={{ maxLength: 8 }}
            label="DNI"
            name="dni"
            size="small"
            value={formik.values.dni}
            onChange={formik.handleChange}
            disabled={!isEditing}
            error={!!formik.touched.dni && !!formik.errors.dni}
            helperText={!!formik.touched.dni && formik.errors.dni}
          />
          {formik.values.platform === 'Usuario prestador' && (
            <>
              <TouringTextField
                onBlur={formik.handleBlur}
                label="Licencia de conducir"
                name="driversLicense"
                size="small"
                value={formik.values.driversLicense}
                onChange={formik.handleChange}
                disabled={!isEditing}
                error={!!formik.touched.driversLicense && !!formik.errors.driversLicense}
                helperText={!!formik.touched.driversLicense && formik.errors.driversLicense}
              />
              <TouringTextField
                onBlur={formik.handleBlur}
                label="Número de teléfono"
                name="phone"
                size="small"
                value={formik.values.phone}
                onChange={formik.handleChange}
                disabled={!isEditing}
                error={!!formik.touched.phone && !!formik.errors.phone}
                helperText={!!formik.touched.phone && formik.errors.phone}
              />
              <TouringTextField
                onBlur={formik.handleBlur}
                label="Correo electónico"
                name="email"
                size="small"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={!isEditing}
                error={!!formik.touched.email && !!formik.errors.email}
                helperText={!!formik.touched.email && formik.errors.email}
              />
            </>
          )}
        </div>

        {canToggleEdit && (
          <div className={classes.horizontalRadioGroup}>
            <Typography
              variant="body1"
              style={{ fontWeight: 'bold', marginRight: 16 }}
            >
              Estado de usuario
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup row>
                <FormControlLabel
                  control={<TouringRadioBox />}
                  label="Habilitado"
                  labelPlacement="end"
                  disabled={!isEditing}
                  onClick={() => {
                    if (isEditing) formik.setFieldValue('enabled', true);
                  }}
                  checked={formik.values.enabled}
                />
                <FormControlLabel
                  control={<TouringRadioBox />}
                  label="Deshabilitado"
                  labelPlacement="end"
                  disabled={!isEditing}
                  onClick={() => {
                    if (isEditing) formik.setFieldValue('enabled', false);
                  }}
                  checked={!formik.values.enabled}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        <div
          className={classes.btnsContainer}
          style={{
            justifyContent: canToggleEdit ? 'space-between' : 'flex-end',
          }}
        >
          {canToggleEdit && (
            <UserCardButton
              type={ButtonType.CHANGE_PASSWORD}
              onClick={handlePasswordUpdateRequest}
            >
              Solicitar cambio de contraseña
            </UserCardButton>
          )}

          <div>
            <Button
              size="medium"
              variant="outlined"
              onClick={
                secondaryBtnOptions?.callback ||
                (() => setShowDiscardConfirm(true))
              }
              className={classes.btn}
            >
              {secondaryBtnOptions?.text || 'Deshacer'}
            </Button>
            <Button
              size="medium"
              variant="contained"
              disabled={!isEditing}
              className={classes.btn}
              type="submit"
            >
              {primaryBtnOptions.text}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
