import { Action } from 'redux';
import { TouringApiErrorResponseData } from 'models';
import { backOfficeRoles } from 'models/rol';
import { UserModel } from 'models/user/user';

type Authorities = backOfficeRoles[];

export const authActions = {
  AUTH_FAILED: 'AUTH_FAILED',
  AUTH_START: 'AUTH_START',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
};

export interface TouringClubAction extends Action<string> {
  payload?:
    | { userData: Partial<UserModel>; authorities: Authorities; token: string }
    | TouringApiErrorResponseData
    | boolean;
}

//Errors model must be define using the API model
export function authenticationHasFailed(
  data: TouringApiErrorResponseData
): TouringClubAction {
  return {
    type: authActions.AUTH_FAILED,
    payload: data,
  };
}

export function authenticating(data: boolean): TouringClubAction {
  return {
    type: authActions.AUTH_START,
    payload: data,
  };
}

export function authenticationHasSucceeded(data: {
  userData: Partial<UserModel>;
  authorities: Authorities;
  token: string;
}): TouringClubAction {
  return {
    type: authActions.AUTH_SUCCESS,
    payload: data,
  };
}

export function logout(data: true): TouringClubAction {
  return {
    type: authActions.LOGOUT_SUCCESS,
    payload: data,
  };
}
