import { LocationModel, TowCompanyModel, TrackingCostResponse } from 'models';
import { TowServiceCostResponse, TowServiceDetailsResponse } from 'models/towService';
import { VehicleServiceModel } from 'models/vehicleService';

export enum ETowBaseType {
  Fixed = 'Fijo',
  Mobile = 'MOVIL',
}

export enum ETowBaseState {
  Available = 'Disponible',
  Absent = 'Ausente',
  Activo = 'Activo',
  Inactivo = 'Inactivo',
}

export interface VehiclesTypeCode {
  GP: number;
  GC: number;
  CT: number;
  UA: number;
  GD: number;
}

export interface TowBaseModel {
  code: string;
  name: string;
  type: ETowBaseType;
  state: ETowBaseState;
  radio: number;
  id?: number;
  location: LocationModel;
  towCompany: TowCompanyModel;
  vehicleServicesType: VehiclesTypeCode;
}

export interface TowBaseServiceVehicle extends Omit<TowBaseModel, 'location' | 'towCompany'> {
  servicesVehicles: VehicleServiceModel[];
}

export type ReducedTowBaseModel = Omit<TowBaseModel, 'towCompany'>;

export interface SuggestedTowBase {
  id: number;
  towBase: TowBaseModel;
  delay: string;
}

export interface SuggestedTowBaseRequest {
  towBaseId: number;
  delay?: number;
}

export interface SuggestedTowBaseUpdateRequest {
  delay: number;
  rejection: boolean;
}

export enum SuggestedTowBaseStatus {
  SIN_CONSULTAR = 'SIN_CONSULTAR',
  CONSULTADO_AUTOMATICAMENTE = 'CONSULTADO_AUTOMATICAMENTE',
  CONSULTADO_MANUALMENTE = 'CONSULTADO_MANUALMENTE',
  NO_RESPONDIO = 'NO_RESPONDIO',
  ACEPTO_EL_SERVICIO = 'ACEPTO_EL_SERVICIO',
  RECHAZO_EL_SERVICIO = 'RECHAZO_EL_SERVICIO',
}

export interface SuggestedTowBaseResponse {
  serviceId: number;
  serviceVehicleId: number;
  delay: number;
  status: SuggestedTowBaseStatus;
  towBase: TowBaseModel;
  towTrackingCost?: TrackingCostResponse;
  insuredTrackingCost?: TrackingCostResponse;
  towServiceCost?: TowServiceCostResponse;
  towServiceDetails?: TowServiceDetailsResponse;
}

export interface Page<Type> {
  totalPages: number;
  totalElements: number;
  size: number;
  content: Type[];
  number: number;
  first: boolean;
  last: boolean;
  empty: boolean;
}

export type SuggestedTowBasePage = Page<SuggestedTowBaseResponse>;

export type TowBasesPage = Page<TowBaseModel>;
