export interface InvoiceModel {
  id: number;
  number: string;
  state: InvoiceState;
  typeDocument: InvoiceType;
  date: string;
  files: InvoiceFiles;
  detail: string;
  amount: number;
  tax: number;
}

export enum InvoiceState {
  Received = 'RECIBIDA',
  Approved = 'APROBADA',
  SendingPayment = 'ENVIO_DE_PAGO',
  Paid = 'PAGADO',
  Nullified = 'ANULADA',
}

export enum InvoiceType {
  TypeA = 'FACTURA_A',
  TypeB = 'FACTURA_B',
  TypeC = 'FACTURA_C',
}

export interface InvoiceFiles {
  fileName: string;
  uri: string;
  fileType: string;
  size: number;
}

export enum MovementType {
  Credit = 'CREDITO',
  Debit = 'DEBITO',
}

export interface MovementFormValues {
  type: MovementType;
  amount: number | string;
  comment: string;
  created: Date;
  observation: string;
}
