import { useState, useContext, useRef } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './UsersManagementStyles';
import Accordion from 'components/accordion/Accordion';
import UserManagementForm, {
  FormAPI,
} from 'components/usersManagement/UserManagementForm/UserManagementForm';
import UserCard from 'components/usersManagement/UserCard/UserCard';
import UserSearchControls from 'components/usersManagement/UserSearchControls/UserSearchControls';
import { SuccessDialog } from 'components/TouringDialogs';
import Loader from 'components/loader/Loader';
import { AxiosInstanceContext } from 'contexts/AxiosInstanceProvider';
import { apiRoutes, formatUrl } from 'routes/apiRoutes';
import { UserModel, TouringApiError } from 'models';
import { SelectedUser } from 'utils/selectors/usersManagement';
import {
  FilterByType,
  QueryFilters,
} from 'components/usersManagement/UserSearchControls/types';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

enum Dialog {
  CLOSED = 'closed',
  CHANGE_PASSWORD_EMAIL_SENT = 'success_email',
  USER_CREATED = 'success_create',
  USER_UPDATED = 'success_update',
}

export default function UsersManagement(): JSX.Element {
  const classes = useStyles();
  const { touringApiService } = useContext(AxiosInstanceContext);
  const [dialogStatus, setDialogStatus] = useState<Dialog>(Dialog.CLOSED);
  const [usersList, setUsersList] = useState<UserModel[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [isSendingForm, setIsSendingForm] = useState<boolean>(false);

  const [isFirstQuery, setIsFirstQuery] = useState<boolean>(true);
  const [isLastQueryPage, setIsLastQueryPage] = useState<boolean>(false);
  const lastEmittedFilters = useRef<QueryFilters>({ page: 0 });
  const usersContainer = useRef<HTMLDivElement>(null);

  const handlePageUpdate = () => {
    const { page, ...params } = lastEmittedFilters.current;
    queryUsers({ page: (page || 0) + 1, ...params });
  };

  useInfiniteScroll({
    containerEl: usersContainer.current,
    isEnabled: !!usersList.length && !isLastQueryPage,
    threshold: 1,
    callback: handlePageUpdate,
  });

  const queryUsers: FilterByType = ({ role, dni, name, lastName, page }) => {
    lastEmittedFilters.current = { role, dni, name, page, lastName };
    setIsFirstQuery(false);

    if (!usersList.length) setLoadingUsers(true);

    touringApiService
      .get(apiRoutes.USERS.getAllFiltered, {
        params: { role, name, dni, page, lastName },
      })
      .then(({ data }: { data: { content: UserModel[] } }) => {
        if (page) {
          setUsersList([...usersList, ...data.content]);
        } else {
          setUsersList(data.content);
          setIsLastQueryPage(false);
        }

        if (!data.content.length) setIsLastQueryPage(true);
      })
      .catch((err: TouringApiError) => console.error(err))
      .finally(() => setLoadingUsers(false));
  };

  const createUser = (userData: Partial<SelectedUser>, formAPI: FormAPI) => {
    const data = {
      driversLicense: userData.driversLicense,
      telephone: userData.phone,
      firstName: userData.name,
      lastName: userData.lastname,
      email: userData.email,
      dni: Number(userData.dni),
      roles: [
        {
          id: Number(userData.role),
        },
      ],
      enabled: userData.enabled,
      towCompany: userData.company,
    };

    setIsSendingForm(true);
    touringApiService
      .post(apiRoutes.USERS.create, data)
      .then(() => {
        queryUsers(lastEmittedFilters.current);
        setDialogStatus(Dialog.USER_CREATED);
        formAPI.cleanForm();
      })
      .catch((err: TouringApiError) => console.error(err))
      .finally(() => setIsSendingForm(false));
  };

  const updateUser = (userData: Partial<SelectedUser>) => {
    const data = {
      driversLicense: userData.driversLicense,
      telephone: userData.phone,
      firstName: userData.name,
      lastName: userData.lastname,
      email: userData.email,
      dni: Number(userData.dni),
      roles: [
        {
          id: Number(userData.role),
        },
      ],
      enabled: userData.enabled,
      towCompany: userData.company,
    };

    setIsSendingForm(true);
    touringApiService
      .patch(formatUrl(apiRoutes.USERS.update, { id: `${userData.id}` }), data)
      .then(({ data: updatedUser }: { data: UserModel }) => {
        const userIndex = usersList.findIndex(
          (user) => user.id === userData.id
        );
        const filteredList = usersList.filter(
          (user) => user.id !== userData.id
        );
        const updatedList = [
          ...filteredList.slice(0, userIndex),
          updatedUser,
          ...filteredList.slice(userIndex),
        ];
        setUsersList(updatedList);
        setDialogStatus(Dialog.USER_UPDATED);
      })
      .catch((err: TouringApiError) => console.error(err))
      .finally(() => setIsSendingForm(false));
  };

  const requestUserPasswordUpdate = (data: Pick<UserModel, 'email'>) => {
    setLoadingUsers(true);
    touringApiService
      .get(formatUrl(apiRoutes.USERS.sendAuthCode, data))
      .then(() => setDialogStatus(Dialog.CHANGE_PASSWORD_EMAIL_SENT))
      .catch((err: TouringApiError) => console.error(err));
    setLoadingUsers(false);
  };

  const renderUsersList = () => {
    if (usersList.length)
      return usersList.map((user) => (
        <UserCard
          key={user.id}
          userData={user}
          onUpdate={updateUser}
          onPasswordUpdateRequest={requestUserPasswordUpdate}
        />
      ));

    return (
      <Typography variant="body1" className={classes.notFoundText}>
        Usuario no encontrado
      </Typography>
    );
  };

  return (
    <>
      <Loader isOpen={isSendingForm} />
      <div className={classes.page}>
        <Accordion heading="Alta de usuarios">
          <div className={classes.greyserContainer}>
            <div className={classes.greyserContainerContent}>
              <UserManagementForm
                primaryBtnOptions={{
                  text: 'Agregar nuevo usuario',
                  callback: createUser,
                }}
              />
            </div>
          </div>
        </Accordion>
        <Accordion heading="Consulta de usuarios">
          <UserSearchControls filterBy={queryUsers} />
          {!isFirstQuery && (
            <div
              className={clsx(
                classes.greyserContainer,
                usersList.length === 0 && classes.notFoundContainer
              )}
              ref={usersContainer}
            >
              {loadingUsers ? (
                <Grid container justifyContent="center">
                  <CircularProgress color="inherit" />
                </Grid>
              ) : (
                renderUsersList()
              )}
            </div>
          )}
        </Accordion>
      </div>

      <SuccessDialog
        open={dialogStatus !== Dialog.CLOSED}
        title={getDialogTitle(dialogStatus)}
        btnText="Continuar"
        onConfirm={() => setDialogStatus(Dialog.CLOSED)}
      />
    </>
  );
}

const getDialogTitle = (status: Dialog) => {
  switch (status) {
    case Dialog.CHANGE_PASSWORD_EMAIL_SENT:
      return '¡Email para cambio de contraseña enviado al usuario!';
    case Dialog.USER_CREATED:
      return '¡Usuario creado exitosamente!';
    case Dialog.USER_UPDATED:
      return '¡Usuario actualizado exitosamente!';
    default:
      return '';
  }
};
