import { ServiceVehicleCostModel, TrackingCostModel } from 'models/assignmentServices';
import { InsurancePolicyModel } from 'models/insurancePolicy';
import { InvoiceModel } from 'models/invoice';
import { LocationModel } from 'models/location';
import { TowCompanyModel } from 'models/towCompany';
import { UserModel } from 'models/user';
import { ServiceVarianceModel, VehicleServiceModel } from 'models/vehicleService';

export namespace AccountMovements {
  export interface AccountMovementsModel {
    id: number;
    amount: number;
    created: Date;
    type: string;
    comment: string;
    towCompany: Pick<TowCompanyModel, 'name' | 'id'> | null;
    observation: string;
    invoice: InvoiceModel;
    service: {
      id: number;
      identifierCode: string;
      plate: string;
      created: Date;
      assigned: Date;
      dateOfService: Date;
      estimatedDateService: Date;
      closed: Date;
      applicantsName: string;
      status: string;
      originLocation: LocationModel;
      endindLocation: LocationModel;
      serviceType: string;
      totalKm: number;
      coveredKm: number;
      exceededKm: number;
      totalTowKm: number;
      vehicleMedium: true;
      vehicleHeavy: true;
      vehicleColor: string;
      gravel: true;
      sinous: true;
      mountain: true;
      night: true;
      priority: string;
      serviceVehicle: VehicleServiceModel;
      towCompany: Partial<TowCompanyModel>;
      driver: UserModel;
      consumed: true;
      closeWithErrors: true;
      insurancePolicy: Partial<InsurancePolicyModel>;
      serviceVehicleCost: ServiceVehicleCostModel;
      insuredTrackingCost: TrackingCostModel;
      touringTrackingCost: TrackingCostModel;
      serviceVariance: ServiceVarianceModel[];
      comments: string;
      totalKmFinal: number;
    };
  }
}
