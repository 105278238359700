import { StatesServices } from 'models/assignmentServices';
import { EServiceType } from 'models/serviceTake';
import { TowCompanyModel } from 'models/towCompany';

export namespace ServiceHistory {
  export interface ServiceHistoryModel {
    id: number;
    serviceType: EServiceType;
    applicantsName: string;
    towCompany: Pick<TowCompanyModel, 'name' | 'id'> | null;
    status: StatesServices;
    identifierCode: string;
    plate: string;
    dateOfService: string;
  }

  export interface User {
    user: ServiceUser;
    status: string;
    type: string;
  }

  interface ServiceUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    rolesUser: ServiceUserRole[];
    enabled: boolean;
  }

  interface ServiceUserRole {
    id: number;
    authority: string;
    description: string;
  }
}
