import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    color: theme.customColors.blackForTexts,
    fontSize: 20,
    fontWeight: 700,
  },
  formGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(3),
    padding: theme.spacing(3, 0),
  },
  dividedFormField: {
    display: 'flex',
    gap: 12,
  },
  horizontalRadioGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  btnsContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),

    '& > div': {
      display: 'flex',
      gap: theme.spacing(2),
    },
  },
  btn: {
    borderRadius: theme.spacing(1),
  },
  selectOption: {
    textTransform: 'capitalize',
  },
}));

export default useStyles;
