import { LocationModel, NoteModel, ServiceHistory } from 'models';
import { VehicleServiceModel } from 'models/vehicleService';
import { InsurancePolicyModel } from 'models/insurancePolicy';
import { StatesServices } from './ServicesStateModel';
import { TowBaseModel } from 'models/towBase';

export enum ServicesPrority {
  High = 'ALTA',
  Low = 'BAJA',
  Medium = 'MEDIA',
}

export interface ServiceVehicleCostModel {
  id: number;
  initialMove: number;
  excedentKm: number;
  gravel: number;
  mountain: number;
  sinous: number;
  vehicleMediumInitialMove: number;
  vehicleMedium: number;
  excedentKmVehicleMediumCopay: number;
  excedentKmCopay: number;
  onlyMechanics: number;
  night: number;
}

export interface TrackingCostModel {
  [index: string]: number;
  id: number;
  night: number;
  sinous: number;
  mountain: number;
  gravel: number;
  excedentKm: number;
  initialMove: number;
  fixedCost: number;
  total: number;
}

export interface AssignmentServicesModel {
  id: number;
  plate: string;
  serviceType: string;
  serviceVehicle: VehicleServiceModel;
  created: string;
  estimatedDateService: string;
  dateOfService: string;
  towCompany: {
    id: number;
    name: string;
    phone: string;
  } | null;
  applicantsName: string;
  coveredKm: number;
  status: StatesServices;
  priority: ServicesPrority;
  comments: string;
  phone: string;
  email: string;
  gravel: boolean;
  sinous: boolean;
  mountain: boolean;
  night: boolean;
  totalKm: number;
  totalTowKm: number;
  totalKmFinal: number;
  exceededKm: number;
  towBase: Pick<TowBaseModel, 'id' | 'radio' | 'name'>;
  originLocation: LocationModel;
  endindLocation: LocationModel;
  serviceVehicleCost: ServiceVehicleCostModel;
  insuredTrackingCost: TrackingCostModel;
  touringTrackingCost: TrackingCostModel;
  insurancePolicy: Partial<InsurancePolicyModel>;
  previousServiceVersion: AssignmentServicesModel | null;
  events?: (NoteModel & ServiceHistory.User)[] | null;
}
