import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  inputRoot: {
    borderRadius: 8,
    fontWeight: 700,
  },
  inputLabel: {
    fontWeight: 700,
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #f44336 !important',
    },
  },
}));

export default function TouringTextField(props: TextFieldProps): JSX.Element {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        classes: {
          ...props.InputProps?.classes,
          root: clsx(classes.inputRoot, props.InputProps?.classes?.root),
          error: clsx(classes.error),
        },
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        classes: {
          ...props.InputLabelProps?.classes,
          root: clsx(classes.inputLabel, props.InputLabelProps?.classes?.root),
        },
      }}
    />
  );
}
