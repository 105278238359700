import { ELocationExactitude } from 'models';

const MAP_CENTER = { lat: -34.59907169143975, lng: -58.45184695385868 };

const GMAPS_LIBRARIES: (
  | 'geometry'
  | 'places'
  | 'drawing'
  | 'localContext'
  | 'visualization'
)[] = ['geometry', 'places', 'drawing'];

const CURRENT_SERVICE_PAGE_SIZE = 500000;

const TAKE_SERVICE_CONSTANTS = {
  STEP_2_INITIAL_VALUES: {
    locationExactitudeOrigin: ELocationExactitude.Exact,
    originLocationReference: '',
    physical_address: null,
    origin: null,
    originMarker: MAP_CENTER,
    zoneType: {
      gravel: false,
      mountain: false,
      sinous: false,
    },
  },
  STEP_3_INITIAL_VALUES: {
    locationExactitudeDestination: ELocationExactitude.Exact,
    destinationLocationReference: '',
    physical_address: null,
    destination: null,
    destinationMarker: MAP_CENTER,
    totalKm: 0,
  },
};

export {
  MAP_CENTER,
  GMAPS_LIBRARIES,
  CURRENT_SERVICE_PAGE_SIZE,
  TAKE_SERVICE_CONSTANTS,
};
