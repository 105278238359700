import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AuthState } from '../reducers/authReducer';
import { authenticationReducer } from './authReducer';
import companyRegistrationReducer from './companyRegistrationReducer';
import notesReducer from './notesReducer';
import servicesAssignmentReducer from './serviceAssignment';
import serviceTakeReducer from './serviceTakeReducer';
import towBasesReducer from './towBasesReducer';

export interface AppState {
  auth?: AuthState;
}

export const initialState: AppState = {
  auth: { token: '' },
};

const config = {
  storage: storage,
  key: 'touring',
  blacklist: ['serviceAssignment'],
};

export default persistCombineReducers(config, {
  auth: authenticationReducer,
  serviceTake: serviceTakeReducer,
  companyRegistration: companyRegistrationReducer,
  notes: notesReducer,
  towBases: towBasesReducer,
  serviceAssignment: servicesAssignmentReducer,
});
