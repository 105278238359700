import {
  makeStyles,
  Theme,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { CSSProperties } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3.75),
    paddingTop: theme.spacing(1.325),
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(2.825),
    right: theme.spacing(2.25),
  },
  closeIcon: {
    width: theme.spacing(2.325),
    height: theme.spacing(2.325),
  },
  title: {
    color: theme.customColors.biscay,
    fontSize: 34,
    fontWeight: 'bold',
    marginTop: theme.spacing(7),
    maxWidth: 335,
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.customColors.stratos,
    margin: theme.spacing(2, 0, 3, 0),
    maxWidth: 335,
  },
  btn: {
    borderRadius: theme.spacing(1),
    minWidth: 155,
    minHeight: 48,
  },
  invert: {
    flexDirection: 'row-reverse',
  },
}));

type BtnOptions = {
  text: string;
  action?: () => void;
};

export interface Props {
  open?: boolean;
  title: string;
  description?: string;
  descriptionStyles?: CSSProperties;
  onConfirmOptions: BtnOptions;
  onCancelOptions: BtnOptions;
  invertButtons?: boolean;
  onCloseClick?: () => void;
}

export default function ConfirmationDialog({
  open = false,
  title,
  description,
  descriptionStyles,
  onConfirmOptions,
  onCancelOptions,
  invertButtons,
  onCloseClick = () => null,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleConfirm = () => {
    if (onConfirmOptions.action) onConfirmOptions.action();
  };

  const handleCancel = () => {
    if (onCancelOptions.action) onCancelOptions.action();
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <IconButton onClick={onCloseClick} className={classes.closeBtn}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <Typography className={classes.title} align="center">
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={classes.description}
        align="center"
        style={descriptionStyles}
      >
        {description}
      </Typography>
      <Grid
        container
        spacing={2}
        classes={{ root: clsx(invertButtons && classes.invert) }}
        justifyContent="center"
      >
        <Grid item>
          <Button
            onClick={handleCancel}
            variant="outlined"
            className={classes.btn}
          >
            {onCancelOptions.text}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleConfirm}
            variant="contained"
            className={classes.btn}
          >
            {onConfirmOptions.text}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
