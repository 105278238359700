import { Company } from '../company';
import { DocumentModel } from '../documents';

export interface TowCompanyModel {
  id?: number;
  businessName?: string;
  address: string;
  phone: string;
  zipCode: string;
  email: string;
  administrativeEmail: string;
  accountType: string;
  earningsTax: string;
  code?: string;
  name: string;
  alias: string;
  cuit: string;
  state: string;
  vatType: string;
  ibbInscriptionNumber: string;
  exemptionInIIBA: boolean;
  iib?: string;
  cbu: string;
  bank: string;
  accountNumber: string;
  documentation: DocumentModel[];
  locality: Partial<Company.LocalityModel>;
  webNotification: boolean;
}

export enum EBankAccountType {
  Checking = 'Cuenta Corriente',
  Savings = 'Caja de Ahorro',
}

export interface towCompanyDetailModel {
  id?: number;
  movementType: number;
  concept: string;
  netAmount: number;
  shippingDate: Date;
  observations: string | number;
}

export type TowCompanyBalanceInformation = Pick<
  TowCompanyModel,
  'id' | 'name' | 'cuit'
> & { amount: number };
