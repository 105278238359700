import { PlaceType } from 'models';

export enum EServiceType {
  Transport = 'TRASLADO',
  Mechanical = 'MECANICA',
}

export enum ELocationExactitude {
  Exact = 'EXACTA',
  Approximate = 'APROXIMADA',
}

export interface ApplicantDataFormValues {
  name: string;
  phone: string;
  email: string;
  serviceType: string;
  serviceDescription: string;
  insuranceCompany: string;
  filterSelect: string;
  filterInput: string;
  priorityLevel: string;
  vehicleColor: string;
  insurancePolicyId: number;
  insuranceCategory: string;
  refundable: boolean;
}

export interface PhysicalAddress {
  province?: string;
  district?: string;
  postal_code?: string;
  locality?: string;
}

export interface ServiceOriginValues {
  locationExactitudeOrigin:
    | ELocationExactitude.Exact
    | ELocationExactitude.Approximate;
  origin: PlaceType | null;
  originMarker: { lat: number; lng: number };
  originLocationReference: string;
  zoneType: { gravel: boolean; mountain: boolean; sinous: boolean };
  physical_address: PhysicalAddress | null;
}

export interface ServiceDestinationValues {
  locationExactitudeDestination:
    | ELocationExactitude.Exact
    | ELocationExactitude.Approximate;
  destination: PlaceType | null;
  destinationMarker: { lat: number; lng: number };
  destinationLocationReference: string;
  physical_address: PhysicalAddress | null;
  totalKm: number;
}
