import { useState, useEffect, useContext, ChangeEvent } from 'react';
import { Typography, Button, MenuItem } from '@material-ui/core';
import useStyles from './UserSearchControlsStyles';
import TouringTextField from 'components/TouringTextField';

import { FilterByType, FilterOption, FilterName } from './types';
import { FILTER_OPTIONS } from './constants';

import { AxiosInstanceContext } from 'contexts/AxiosInstanceProvider';
import { apiRoutes } from 'routes/apiRoutes';
import { RolModel, TouringApiError } from 'models/';

interface Props {
  filterBy?: FilterByType;
}

interface FormErrors {
  filter: string;
  value: string;
}

const errorInitialValues = {
  filter: '',
  value: '',
};

export default function UserSearchControls({ filterBy }: Props): JSX.Element {
  const classes = useStyles();
  const { touringApiService } = useContext(AxiosInstanceContext);
  const [userRolesList, setUserRolesList] = useState<RolModel[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterName | ''>('');
  const [filterValue, setFilterValue] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>(errorInitialValues);

  const handleFilterChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter(ev.target.value as FilterName);
    setErrors(errorInitialValues);
    setFilterValue('');
  };

  const hasError = () => {
    if (selectedFilter === '' || filterValue === '') {
      if (selectedFilter === '')
        setErrors((prevProps) => ({ ...prevProps, filter: 'Campo vacío' }));
      if (filterValue === '')
        setErrors((prevProps) => ({ ...prevProps, value: 'Campo vacío' }));
      return true;
    }
    return false;
  };

  const emitFilters = () => {
    if (filterBy && !hasError()) {
      filterBy({
        [selectedFilter]:
          selectedFilter === 'role' ? Number(filterValue) : filterValue,
      });
    }
  };

  useEffect(() => {
    touringApiService
      .get(apiRoutes.ROLES.getAll)
      .then(({ data }: { data: RolModel[] }) => setUserRolesList(data))
      .catch((err: TouringApiError) => console.log(err));
  }, []);

  return (
    <div className={classes.container}>
      <Typography variant="body2" classes={{ root: classes.filterTitle }}>
        Filtrar por
      </Typography>
      <TouringTextField
        fullWidth
        select
        variant="outlined"
        size="small"
        name="filter"
        label="Elegir filtro"
        value={selectedFilter}
        onChange={handleFilterChange}
        classes={{ root: classes.filterSelector }}
        error={Boolean(errors.filter)}
        helperText={errors.filter}
      >
        {FILTER_OPTIONS.map((filter: FilterOption) => (
          <MenuItem value={filter.value} key={filter.value}>
            <Typography className={classes.selectOption} align="left" noWrap>
              {filter.label}
            </Typography>
          </MenuItem>
        ))}
      </TouringTextField>
      {selectedFilter === 'role' && (
        <TouringTextField
          fullWidth
          select
          variant="outlined"
          size="small"
          label="Rol"
          name="role"
          value={filterValue}
          onChange={(ev) => {
            setFilterValue(ev.target.value);
            setErrors((prevProps) => ({ ...prevProps, value: '' }));
          }}
          classes={{ root: classes.roleSelector }}
          error={Boolean(errors.value)}
          helperText={errors.value}
        >
          {userRolesList.map((role: RolModel) => (
            <MenuItem value={`${role.id}`} key={role.id}>
              <Typography className={classes.selectOption} align="left" noWrap>
                {role.description.toLowerCase()}
              </Typography>
            </MenuItem>
          ))}
        </TouringTextField>
      )}

      {selectedFilter !== 'role' && (
        <TouringTextField
          disabled={!selectedFilter}
          size="small"
          classes={{ root: classes.searchBar }}
          label={selectedFilter ? 'Buscar' : 'Seleccione un filtro'}
          value={filterValue}
          onChange={(ev) => {
            setFilterValue(ev.target.value);
            setErrors((prevProps) => ({ ...prevProps, value: '' }));
          }}
          error={Boolean(errors.value)}
          helperText={errors.value}
          inputProps={selectedFilter === "dni" ? { maxLength: 8 } : {}}
        />
      )}
      <Button
        variant="contained"
        className={classes.searchBtn}
        onClick={emitFilters}
      >
        Buscar
      </Button>
    </div>
  );
}
