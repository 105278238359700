import { UserModel, TowCompanyModel } from 'models';
import { isTouringUser } from 'utils/helperFunctions';

export interface SelectedUser {
  platform: string;
  role: string;
  name: string;
  lastname: string;
  email: string;
  dni: string;
  driversLicense: string;
  phone: string;
  enabled: boolean;
  company?: Pick<TowCompanyModel, 'id' & 'name'>;
  id?: number;
}

export function selectUserData(user: UserModel): SelectedUser {
  const {
    id,
    firstName,
    lastName,
    dni,
    email,
    driversLicense,
    telephone,
    rolesUser,
    enabled,
    towCompany,
  } = user;

  return {
    id,
    phone: telephone,
    platform: isTouringUser(user) ? 'Usuario Touring' : 'Usuario prestador',
    driversLicense: `${driversLicense}`,
    role: `${rolesUser[0].id}`,
    dni: `${dni}`,
    name: firstName,
    lastname: lastName,
    email,
    enabled,
    company: towCompany && {
      id: towCompany?.id,
      name: towCompany?.name,
    },
  };
}
