export const mockedPlatforms = ['Usuario Touring', 'Usuario prestador'];

export const defaultInitialValues = {
  platform: 'Usuario Touring',
  role: '',
  name: '',
  lastname: '',
  email: '',
  dni: '',
  driversLicense: '',
  phone: '',
  enabled: true,
};
