import { TowBaseModel } from 'models';
import { TowBasesAction } from 'redux/actions/towBases';

interface TowBasesState {
  values: TowBaseModel[];
  fetchDate?: Date;
  loading: boolean;
  error?: string;
}

const initialState: TowBasesState = {
  values: [],
  loading: true,
};

function towBasesReducer(
  state: TowBasesState = initialState,
  action: TowBasesAction
): TowBasesState {
  switch (action.type) {
    case 'LOADING_TOW_BASES': {
      return {
        ...state,
        values: state.values,
        fetchDate: new Date(),
        loading: true,
        error: undefined,
      };
    }
    case 'SET_TOW_BASES': {
      return {
        ...state,
        values: action.payload,
        loading: false,
        error: undefined,
      };
    }
    case 'TOW_BASES_ERROR': {
      return {
        ...state,
        values: state.values,
        loading: false,
        error: undefined,
      };
    }
    default: {
      return state;
    }
  }
}

export type { TowBasesState };

export default towBasesReducer;
