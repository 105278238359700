import { PlaceType } from 'models/gmaps';
import { RolModel } from 'models/rol';

export namespace Company {
  export enum EIvaSituationType {
    IvaResponsableSigned = 'IVA Responsable Inscripto',
    IvaResponsableNotSigned = 'IVA Responsable no Inscripto',
    IvaNotResponsable = 'IVA no Responsable',
    IvaExentSubject = 'IVA Sujeto Exento',
    FinalConsumer = 'Consumidor Final',
    MonotaxResponsable = 'Responsable Monotributo',
    NotCategorizedSubject = 'Sujeto no Categorizado',
    OutsideProvider = 'Proveedor del Exterior',
    OutsideClient = 'Cliente del Exterior',
    IvaFree = 'IVA Liberado – Ley Nº 19.640',
    IvaResponsableSignedPerceptionAgent = 'IVA Responsable Inscripto – Agente de Percepción',
    EventualSmallTaxPayer = 'Pequeño Contribuyente Eventual',
    SocialMonoTax = 'Monotributista Social',
    SocialEventualSmallTaxPayer = 'Pequeño Contribuyente Eventual Social',
  }

  export enum ETaxSituation {
    FirstCategory = 'Primera categoría',
    SecondCategory = 'Segunda categoría',
    ThirdCategory = 'Tercera categoría',
    FourthCategory = 'Cuarta categoría',
  }

  export type Base = {
    id: number;
    name: string;
  };

  export type CountryModel = {
    id: number;
    name: string;
  }

  export type ProvinceModel = {
    id: number;
    name: string;
    country: CountryModel;
  };

  export interface LocalityModel {
    id: number;
    name: string;
    parentText: string;
    province: ProvinceModel;
  }

  export type IVASituation = Base;

  export type TaxSituation = Base;

  export interface BankModel extends Base {
    code: string;
  }

  export type ContactInfoDataValues = {
    fullName: string;
    cuit: string;
    phone: string;
    email: string;
    adminEmail: string;
    alias?: string;
  };

  export type NotificationConfiguration = {
    webNotification: boolean;
  }

  export type LocationDataValues = {
    provinceId?: number | null;
    localityId?: number | null;
    address: string;
    zipCode: string;
  };

  export type BankingDataValues = {
    bank: string;
    cbu: string;
    accountType: string;
    accountNumber: string;
  };

  export interface BaseLocationModel {
    radio: string;
    placeType: PlaceType;
    lng: number;
    lat: number;
    updateId?: number;
  }

  export type GeolocationDataValues = {
    kmRadius: string;
    locations: BaseLocationModel[];
  };

  export type IvaDataValues = {
    ivaSituation?: string | null;
    taxSituation?: string | null;
    iibbNumber?: string | null;
    cabaExemption?: boolean | null;
  };

  interface PdfFile extends File {
    id?: number;
  }

  export type PdfFileValues = {
    afip: PdfFile | null;
    iibb: PdfFile | null;
    cbu: PdfFile | null;
    exeptions: PdfFile | null;
  };

  export type InformationFormValues = {
    state: string;
    contactValues: ContactInfoDataValues;
    bankingValues: BankingDataValues;
    locationValues: LocationDataValues;
    geoLocationValues: GeolocationDataValues;
    ivaValues: IvaDataValues;
    pdfFiles: PdfFileValues;
    notificationConfiguration: NotificationConfiguration;
    towCompanyId?: string;
  };

  export type VehicleInfoDataValues = {
    brand?: string;
    model?: string;
    year?: string;
    patent?: string;
    base: string;
    type?: string;
  };

  export type ScheduleDataValues = {
    mondayToFriday: {
      active: boolean;
      from: string;
      to: string;
    };
    saturday: {
      active: boolean;
      from: string;
      to: string;
    };
    restDay: {
      active: boolean;
      from: string;
      to: string;
    };
  };

  export type TariffDataValues = {
    circulationPrice: string;
    surplusPrice: string;
    mediumInitialMove: string;
    semiHeavyPercentage: string;
    mechanicPercentage: string;
    mountainPercentage: string;
    gravelPercentage: string;
    sinuousPercentage: string;
    nightPercentage: string;
    excedentKmCopay: string;
    excedentKmVehicleMediumCopay: string;
  };

  export type FleetFormValues = {
    id: number | null;
    enabled?: boolean;
    vehicleInfoValues: VehicleInfoDataValues;
    scheduleValues: ScheduleDataValues;
    tariffValues: TariffDataValues;
  };

  export type CollaboratorFormValues = {
    id: number | null;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    rolesUser?: RolModel[];
    dni: string;
    license: string;
    phone: string;
    enabled?: boolean;
  };
}
