import { Action } from 'redux';
import { Company, TowBaseModel } from 'models';

export enum ECompanyRegistrationActions {
  SET_COMPANY_INFORMATION = 'SET_COMPANY_INFORMATION',
  SET_FLEET = 'SET_FLEET',
  SET_COLLABS = 'SET_COLLABS',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_COMPANY_ID = 'SET_COMPANY_ID',
  SET_TOW_BASES_LIST = 'SET_TOW_BASES_LIST',
}

export interface ICompanyRegistrationAction extends Action<string> {
  payload?:
    | Company.InformationFormValues
    | Company.FleetFormValues[]
    | Company.CollaboratorFormValues[]
    | TowBaseModel[]
    | []
    | number
    | void
    | null;
}

export function setCompanyInformation(
  payload: Company.InformationFormValues | null
): ICompanyRegistrationAction {
  return {
    type: ECompanyRegistrationActions.SET_COMPANY_INFORMATION,
    payload,
  };
}

export function setFleet(
  payload: Company.FleetFormValues[] | []
): ICompanyRegistrationAction {
  return {
    type: ECompanyRegistrationActions.SET_FLEET,
    payload,
  };
}

export function setCompanyId(payload: number): ICompanyRegistrationAction {
  return { type: ECompanyRegistrationActions.SET_COMPANY_ID, payload };
}

export function setCollabs(
  payload: Company.CollaboratorFormValues[] | []
): ICompanyRegistrationAction {
  return {
    type: ECompanyRegistrationActions.SET_COLLABS,
    payload,
  };
}

export function setInitialValues(): ICompanyRegistrationAction {
  return {
    type: ECompanyRegistrationActions.SET_INITIAL_STATE,
  };
}

export function setTowBasesList(
  payload: TowBaseModel[]
): ICompanyRegistrationAction {
  return {
    type: ECompanyRegistrationActions.SET_TOW_BASES_LIST,
    payload,
  };
}
