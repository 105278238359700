import { useEffect, useRef } from 'react';

type Callback = () => void;

interface Params {
  containerEl: HTMLElement | null | undefined;
  callback: Callback;
  threshold?: number; // between 0 and 1
  margin?: string;
  isEnabled: boolean;
}

const handleObserver = (callback: Callback, isEnabled: boolean) => {
  return (entries: IntersectionObserverEntry[]) => {
    const target = entries[0];

    if (target.isIntersecting && isEnabled) {
      callback();
    }
  };
};

export default function useInfiniteScroll({
  containerEl,
  callback,
  threshold = 0, // Percentage of the element that must be visible to consider interception true. 0 means 1px - 1 means all the element
  margin = '0px',
  isEnabled = true,
}: Params): void {
  const observer = useRef<IntersectionObserver | undefined>();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: margin,
      threshold,
    };

    observer.current = new IntersectionObserver(
      handleObserver(callback, isEnabled),
      options
    );

    if (containerEl) {
      const { children } = containerEl;

      if (children.length) {
        const target = children[children.length - 1];
        observer.current?.observe(target);
      }
    }

    return () => observer.current?.disconnect();
  }, [containerEl, threshold, margin, callback, isEnabled]);
}
