import { isEmpty } from 'utils/helperFunctions';

export const apiRoutes = {
  AUTH: {
    login: '/auth/token',
    changePassword: '/users/resetPassword',
    generateResetToken: '/users/{email}/sendAutenticatioCode',
    validateResetToken: '/users/{email}/validationAuthenticationCode/{code}',
    resetPasswordNoAuth: '/users/{email}/resetpassword',
  },
  SERVICE_TAKE: {
    sendNotification: '/towCompanies/{companyId}/sendNotification',
    getOnboardingQuestionnaire: '/onboardingQuestionnaire',
    service: '/service',
  },
  COMPANY_REGISTRATION: {
    postCreateTowCompanies: '/towCompanies',
    getProvinces: '/province',
    getLocalities: '/province/{id}/locality',
    getBanks: '/bank',
    createTowCompany: '/towCompanies',
    updateTowCompany: '/towCompanies/{id}',
    createUser: '/users',
    updateUser: '/users/{id}',
    deleteUser: '/users/{id}',
    findRoles: '/roles',
    findAllVehicleServiceType: '/vehicleServiceType',
    findVehicleServicesByTowCompany: '/vehicleService/filter',
    createVehicleService: '/vehicleService',
    deleteVehicleService: '/vehicleService/{id}',
    updateCostVehicleService: '/vehicleService/{id}/cost',
    updateServiceVehicle: '/vehicleService/{id}',
    updateCostServiceVehicle: '/vehicleService/{id}/cost',
    towBasesByCompanyId: '/towCompanies/{id}/towBase',
    addNewRoleToUser: '/user/{userId}/role/{roleId}',
    deleteRoleToUser: '/user/{userId}/role/{roleId}',
    getDocumentation: '/towCompany/{towCompanyId}/documentation',
    getFileToDownload: '/towCompany/{towCompanyId}/documentation/{idDocumentation}/download',
    existsByAlias: '/towCompanies/{alias}/exists',
  },
  SERVICES: {
    findAllTowCompanies: '/towCompanies',
    findAllTowBases: '/towBases',
    findTowBaseById: '/towBases/{id}',
    findServiceVehiclesByTowBaseId: '/towBases/{id}/serviceVehicle',
    findAllServices: '/service/filter/?page={pageInit}&size={pageSize}',
    findAllServicesByUser: '/service/filterBackOffice/?userId={userId}&page={pageInit}&size={pageSize}',
    findAllHistoricServices: '/service/historic/?page={pageInit}&size={pageSize}',
    getServiceInfo: '/service/{id}',
    changeServicePriority: '/service/{serviceId}/priority/{priority}',
    assignService: '/service/{id}/asignate',
    calculateServiceCosts: '/service/{serviceId}/serviceVehicle/{serviceVehicleId}/costs',
    cancelService: '/service/{serviceId}/cancel',
    startService: '/service/{serviceId}/start',
    takeService: '/service/{serviceId}/take',
    declineService: '/service/{serviceId}/decline',
    closeService: '/service/{serviceId}/close',
    towardsDestination: '/service/{serviceId}/towardsDestion',
    assignDriver: '/service/{serviceId}/user/{userId}/assign',
    reportDifferences: '/service/{serviceId}/variance',
    findSuggestedTowBases: '/service/{serviceId}/suggested-tow-bases',
    createSuggestedTowBase: '/service/{serviceId}/suggested-tow-bases',
    updateSuggestedTowBase: '/service/{serviceId}/suggested-tow-bases/{towBaseId}',
    removeSuggestedTowBase: '/service/{serviceId}/suggested-tow-bases/{towBaseId}',
    consultSuggestedTowBasesDelay: '/service/{serviceId}/suggested-tow-bases/delay',
  },
  GMAPS: {
    gmaps_autocomplete: '/gmaps/autocomplete/{session}/{input}',
    gmaps_autocomplete_detail: '/gmaps/place/{session}/{input}',
  },
  ADMIN: {
    getInsurancesByPolicy: '/policy/{policy}/company/{company}',
    getInsurancesByPlate: '/plate/{plate}/company/{company}',
    getInsurancesByDni: '/dni/{dni}/company/{company}',
  },
  NOTES: {
    addNotes: '/service/{id}/note',
    getAllNotes: '/service/{id}/note',
  },
  USERS: {
    getAll: '/users',
    getAllFiltered: '/users/filter',
    create: '/users',
    update: '/users/{id}',
    getUserById: '/users/{id}',
    delete: '/users/{id}',
    sendAuthCode: '/users/{email}/sendAutenticatioCode',
  },
  ROLES: {
    getAll: '/roles',
  },
  TOW_COMPANIES: {
    getAll: '/towCompanies',
    getAllTowCompanyUsers: '/towCompanies/{id}/user',
    getCurrentAccountBalance: '/towCompany/{id}/currentAccountBalance',
    getAllFiltered: '/towCompany/filter',
    getTowCompany: '/towCompanies/{towCompanyId}',
  },
  ACCOUNTANT_MOVEMENT: {
    postAccountingMovement: '/towCompany/{towCompanyId}/accountingMovement',
    getAccountantMovement: '/accountantMovement/filter',
  },
  INVOICES: {
    invoiceFilter: 'invoice/filter',
    updateInvoiceState: '/towCompany/{towCompanyId}/invoice/{invoiceId}/transitionState',
    updateInvoice: '/towCompany/{towCompanyId}/invoice/{invoiceId}',
    downloadInvoice: '/towCompany/{idCompany}/invoice/{idInvoice}/download',
    uploadInvoice: '/towCompany/{towCompanyId}/invoice',
  },
  DISTANCE: {
    getDistance: '/distance',
  },
  TOW_BASES: {
    searchTowBases: '/towBases/search',
  },
  POLICY: {
    coverage: '/policies/{id}/coverage',
  },
  INSURANCES: {
    createNewBroker: '/insuranceCompany',
    editBroker: '/insuranceCompany',
    getAllInsurancesCompanies: '/insuranceCompany',
    getActiveInsurancesCompanies: '/activeInsuranceCompanies',
  }
};

export function formatUrl(
  url: string,
  params: { [key: string]: string | number },
  isRouterUrl = false,
  queryParams?: Record<string, string | number>
): string {
  let enhancedUrl: string = url;

  Object.keys(params).forEach((key: string) => {
    enhancedUrl = enhancedUrl.replace(isRouterUrl ? `:${key}` : `{${key}}`, params[key] as string);
  });

  if (queryParams && !isEmpty(queryParams)) {
    enhancedUrl = `${enhancedUrl}?`;

    const queryParamsKeysArray = Object.keys(queryParams);

    const totalValues = queryParamsKeysArray.length;

    queryParamsKeysArray.forEach((key: string, index: number) => {
      enhancedUrl = `${enhancedUrl}${key}=${queryParams[key]}`;

      enhancedUrl = index !== totalValues - 1 ? `${enhancedUrl}&` : enhancedUrl;
    });
  }
  return enhancedUrl;
}
