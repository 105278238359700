import {
  ApplicantDataFormValues,
  ServiceDestinationValues,
  ServiceOriginValues,
} from 'models';
import {
  ServiceTakeAction,
  serviceTakeActions,
} from 'redux/actions/serviceTake';

export interface ServiceTakeState {
  firstStepValues: ApplicantDataFormValues | null;
  secondStepValues: ServiceOriginValues | null;
  thirdStepValues: ServiceDestinationValues | null;
}

const initialState: ServiceTakeState = {
  firstStepValues: null,
  secondStepValues: null,
  thirdStepValues: null,
};

export default function serviceTakeReducer(
  state = initialState,
  action: ServiceTakeAction
): ServiceTakeState {
  switch (action.type) {
    case serviceTakeActions.SET_FIRST_STEP_VALUES:
      return {
        ...state,
        firstStepValues: action.payload as ApplicantDataFormValues | null,
      };
    case serviceTakeActions.SET_SECOND_STEP_VALUES:
      return {
        ...state,
        secondStepValues: action.payload as ServiceOriginValues | null,
      };
    case serviceTakeActions.SET_THIRD_STEP_VALUES:
      return {
        ...state,
        thirdStepValues: action.payload as ServiceDestinationValues | null,
      };
    case serviceTakeActions.CLEAN_SERVICE_TAKE:
      return {
        ...state,
        firstStepValues: null,
        secondStepValues: null,
        thirdStepValues: null,
      };
    default:
      return state;
  }
}
