import { ENoteType, NoteModel } from 'models';
import { ENotesActions, NotesAction } from 'redux/actions/notes';

interface NotesState {
  operatorNotes: NoteModel[];
  towCompanyNotes: NoteModel[];
  serviceId: number;
}

const initialState: NotesState = {
  operatorNotes: [],
  towCompanyNotes: [],
  serviceId: 0,
};

export default function notesReducer(
  state = initialState,
  action: NotesAction
): NotesState {
  switch (action.type) {
    case ENotesActions.ADD_NEW_NOTE: {
      const note = action.payload as NoteModel;

      if (action.meta === ENoteType.TowCompany)
        return { ...state, towCompanyNotes: [...state.towCompanyNotes, note] };

      return { ...state, operatorNotes: [...state.operatorNotes, note] };
    }
    case ENotesActions.LOAD_NOTES: {
      const serviceId = Number(action.meta);

      if (action.payload) {
        const notes = action.payload as NoteModel[];
        const operatorNotes = notes.filter((note: NoteModel) => note.private);
        const towCompanyNotes = notes.filter(
          (note: NoteModel) => !note.private
        );

        return {
          ...state,
          operatorNotes,
          towCompanyNotes,
          serviceId,
        };
      }
      return { ...state, serviceId };
    }
    case ENotesActions.CLEAN_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
