import { makeStyles, Theme } from '@material-ui/core';
import { AccordionFormProps } from './Accordion';

// eslint-disable-next-line
const useStyles = ({
  removeBorder,
  noElevation,
  backgroundColor,
  bottomSpacing,
  smallHeading,
  fullWidth,
}: AccordionFormProps) =>
  makeStyles((theme: Theme) => ({

    accordion: {
      border: removeBorder ? '' : `solid 1px ${theme.customColors.astral}`,
      boxShadow: noElevation ? 'none' : '',
      backgroundColor: backgroundColor ? backgroundColor : '',
      borderRadius: 12,
      marginBottom: bottomSpacing ? bottomSpacing : 30,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: fullWidth ? '100%' : '',
      '&:first-child': {
        borderRadius: 12,
      },
      '&:last-child': {
        borderRadius: 12,
      },
    },
    heading: {
      ...theme.typography.subtitle2,
      fontSize: smallHeading ? 16 : theme.typography.h4.fontSize,
    },
    icon: {
      width: 25,
      '& > path': {
        fill: theme.customColors.biscay,
      },
    },
    closeIcon: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      '& > path': {
        fill: theme.customColors.astral,
      },
    },
    headerButton: {
      marginRight: theme.spacing(2),
      borderRadius: 8,
    },

    editButton: {
      marginRight: theme.spacing(2),
      backgroundColor: theme.customColors.white,
      color: theme.customColors.biscay,
      borderColor: theme.customColors.biscay,
      borderStyle: 'solid',
      borderWidth: 1,
      fontWeight: 400,
      fontSize: 14,
      '&:hover': {
        color: theme.customColors.white,
      },
      borderRadius: 8,
      width: theme.spacing(11.8),
      height: theme.spacing(3.25),
    },
    editIcon: {
      marginLeft: 5,
    },
  }));

export default useStyles;
