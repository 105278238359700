import { EnumStatus } from 'models/insurances';

export enum EServicesProvided {
  Service1 = 'servicio 1',
  Service2 = 'servicio 2',
  Service3 = 'servicio 3',
}

export interface InsurancePolicyModel {
  id?: number;
  annualServices: number | null;
  monthlyServices: number | null;
  plate: string;
  insuranceNumber: string | null;
  policy?: string;
  policyState: EnumStatus;
  transferApt: boolean;
  assistanceApt: boolean | null;
  servicesProvided: EServicesProvided | null;
  name: string;
  phone: string;
  vehicleWeight: string;
  vehicleHigh: string;
  vehicleLong: string;
  vehicleYear: string;
  vehicleType: string;
  vehicleModel: string;
  vehicleBrand: string;
  vehicleGearbox: string;
  vehicleDriveType: string;
  vehicleColor: string;
  error: string;
  insuranceCompany: {
    id?: number;
    code: string;
    name: string;
    active: boolean;
  };
  category: {
    id?: number;
    category: string;
    annualAmount: number;
    monthlyAmount: number;
    linearFreeKm: number;
    totalFreeKm: number;
    insuredCharge: number;
    calendarYear: boolean;
    calendarMonth: boolean;
  };
  observations: string | null;
}
