import { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { AxiosInstanceContext } from 'contexts/AxiosInstanceProvider';
import { AUTH_ROUTES, NOT_AUTH_ROUTES, paths } from './routeList';
import JWTDecode from 'jwt-decode';
import { TokenModel } from 'models/token';

const AuthorizedRoutes = () => (
  <Switch>
    {AUTH_ROUTES.map((route) => (
      <Route {...route} key={route.name} />
    ))}
    <Route path="*" render={() => <Redirect to={paths.dashboard.path} />} />
  </Switch>
);

const NotAuthorizedRoutes = (props: { expired: boolean }) => (
  <Switch>
    {}
    {NOT_AUTH_ROUTES.map((route) => (
      <Route {...route} key={route.name} />
    ))}
    {props.expired ? <Redirect to={paths.logout.path} /> : null}
    <Route path="*" render={() => <Redirect to={paths.login.path} />} />
  </Switch>
);

export default function Routes(): JSX.Element {
  const { token } = useContext(AxiosInstanceContext);
  let expired = false;
  if (token) {
    const json = JWTDecode(token) as TokenModel;
    if (json.exp <= Math.floor(new Date().getTime() / 1000)) {
      expired = true;
    }
  }

  return (
    <Router>
      {expired || !token ? (
        <NotAuthorizedRoutes expired={expired} />
      ) : (
        <AuthorizedRoutes />
      )}
    </Router>
  );
}
