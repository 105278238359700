import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { Suspense } from 'react';
import Routes from 'routes/Routes';
import FallbackLoader from 'components/loader/Loader';
import theme from 'styles/theme';
import { store, persistor } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import AxiosInstanceProvider from 'contexts/AxiosInstanceProvider';
import { useLoadScript } from '@react-google-maps/api';
import { window } from 'utils/window';
import dayjs from 'dayjs';
import 'normalize.css';
import './App.css';
import 'assets/fonts/Lato/Lato-Regular.ttf';
import 'dayjs/locale/es';
import { GMAPS_LIBRARIES } from 'utils/constants';

dayjs().format('es');

function App(): JSX.Element {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${window.env.REACT_APP_GMAPS_API_KEY}`,
    libraries: GMAPS_LIBRARIES,
  });

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AxiosInstanceProvider>
            <Suspense fallback={<FallbackLoader />}>
              {isLoaded ? <Routes /> : <FallbackLoader />}
            </Suspense>
          </AxiosInstanceProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
