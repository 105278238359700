import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    margin: theme.spacing(1.5, 0.5, 4),
  },
  filterTitle: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
    minWidth: 65,
  },
  filterSelector: {
    maxWidth: 180,
  },
  searchBar: {
    width: '100%',
  },
  roleSelector: {
    width: '100%',
  },
  selectOption: {
    textTransform: 'capitalize',
  },
  searchBtn: {
    borderRadius: theme.spacing(1),
    minWidth: 106,
  },
}));

export default useStyles;
