import {
  CSSProperties,
  ReactNode,
  Dispatch,
  SetStateAction,
  useState,
  MouseEvent,
  SyntheticEvent,
} from 'react';
import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Button,
} from '@material-ui/core';
import useStyles from './accordionStyles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import Close from '@material-ui/icons/Close';

export interface AccordionFormProps {
  heading?: string;
  smallHeading?: boolean;
  children?: ReactNode;
  removeBorder?: boolean;
  backgroundColor?: CSSProperties['color'];
  noElevation?: boolean;
  bottomSpacing?: string;
  thinAccordion?: boolean;
  isGeolocalizating?: boolean;
  setIsGeolocalizating?: Dispatch<SetStateAction<boolean>>;
  accordionButtonAction?: () => void;
  buttonActionText?: string;
  isEditButton?: boolean;
  fullWidth?: boolean;
  disableAccordion?: boolean;
  controlAccordionOpen?: boolean;
  setCloseAccordion?: Dispatch<SetStateAction<boolean>>;
}
export default function AccordionForm(props: AccordionFormProps): JSX.Element {
  const {
    isGeolocalizating,
    setIsGeolocalizating,
    controlAccordionOpen,
    setCloseAccordion,
    heading = '',
    children = <></>,
  } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const classes = useStyles(props)();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const handleOpenAccordion = (
    _: SyntheticEvent<Element, Event>,
    expanded: boolean
  ): void => {
    setIsOpen(expanded);
  };

  const accordionButtonOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (props.accordionButtonAction) {
      props.accordionButtonAction();
    }
  };

  return (
    <Accordion
      expanded={controlAccordionOpen ?? open}
      classes={{ root: classes.accordion }}
      disabled={props.disableAccordion}
      onChange={handleOpenAccordion}
    >
      <AccordionSummary
        onClick={() => {
          if (!isGeolocalizating) {
            if (
              typeof controlAccordionOpen === 'boolean' &&
              setCloseAccordion
            ) {
              setCloseAccordion(!controlAccordionOpen);
              return;
            }
            setOpen(!open);
          }
        }}
        expandIcon={
          !isGeolocalizating ? (
            <ArrowDownIcon className={classes.icon} />
          ) : (
            <Close
              className={classes.closeIcon}
              onClick={() => {
                if (setIsGeolocalizating && isGeolocalizating)
                  setIsGeolocalizating(false);
              }}
            />
          )
        }
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography component="h3" classes={{ root: classes.heading }}>
            {heading}
          </Typography>
          {props.accordionButtonAction && (
            <Button
              variant="contained"
              className={props.isEditButton ? classes.editButton : classes.headerButton}
              disabled={!isOpen}
              onClick={accordionButtonOnClick}
            >
              {props.buttonActionText}
              {props.isEditButton && <EditIcon className={classes.editIcon} />}
            </Button>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
