import { makeStyles, Theme } from '@material-ui/core';
import { hexToRgbA } from 'utils/helperFunctions';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: theme.customColors.white,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2.5),
    height: '100%',

    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  deleteBtn: {
    borderColor: theme.customColors.redRibbon,
    color: theme.customColors.redRibbon,
    marginLeft: theme.spacing(2),

    '&:hover': {
      borderColor: theme.customColors.redRibbon,
      color: theme.customColors.redRibbon,
      backgroundColor: hexToRgbA(theme.customColors.redRibbon, 0.02),
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  userTitle: {
    fontSize: 20,
    fontWeight: 700,
  },
}));

export default useStyles;
