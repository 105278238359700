import {
  ApplicantDataFormValues,
  ServiceDestinationValues,
  ServiceOriginValues,
} from 'models';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'redux/store';

export enum serviceTakeActions {
  SET_FIRST_STEP_VALUES = 'SET_FIRST_STEP_VALUES',
  SET_SECOND_STEP_VALUES = 'SET_SECOND_STEP_VALUES',
  SET_THIRD_STEP_VALUES = 'SET_THIRD_STEP_VALUES',
  CLEAN_SERVICE_TAKE = 'CLEAN_SERVICE_TAKE',
}

export interface ServiceTakeAction extends Action<string> {
  payload?:
    | ApplicantDataFormValues
    | ServiceOriginValues
    | ServiceDestinationValues
    | null;
}

type ServiceTakeThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  ServiceTakeAction
>;

export function setFirstStepValues(
  payload: ApplicantDataFormValues | null
): ServiceTakeAction {
  return {
    type: serviceTakeActions.SET_FIRST_STEP_VALUES,
    payload,
  };
}

export function setSecondStepValues(
  payload: ServiceOriginValues | null
): ServiceTakeAction {
  return {
    type: serviceTakeActions.SET_SECOND_STEP_VALUES,
    payload,
  };
}

export function setThirdStepValues(
  payload: ServiceDestinationValues | null
): ServiceTakeAction {
  return {
    type: serviceTakeActions.SET_THIRD_STEP_VALUES,
    payload,
  };
}

export function cleanServiceTake(): ServiceTakeAction {
  return {
    type: serviceTakeActions.CLEAN_SERVICE_TAKE,
  };
}
