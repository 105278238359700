import * as React from 'react';
import { useState, ReactNode } from 'react';
import axios from 'axios';
import { window } from 'utils/window';

// This Context is to provide a configured Axios instance to the whole app

// It's configured using the API Config in the .env file.

// In order to use it you have to import the AxiosInstanceContext in your component
// then connect it using useContext hook and store the resulting value in { touringApiService, updateApiToken }
// the first value provides you the axios instance ready to use (i.e touringApiService.get('/test')), second value
// is to update the axios instance token mostly used in case of a login, logout or token refresh.

const touringApiService = axios.create({
  baseURL: window.env.REACT_APP_API_HOST,
  withCredentials: true,
});

const ContextDefaultValue = {
  touringApiService,
  // eslint-disable-next-line
  updateApiToken: (token: string | ((prevToken: string) => string)) => {},
  token: '',
  CancelToken: axios.CancelToken,
};

export const AxiosInstanceContext = React.createContext(ContextDefaultValue);

export function AxiosInstanceProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [token, updateApiToken] = useState<string>(() => readToken());

  touringApiService.defaults.headers.common['Authorization'] = token;

  return (
    <AxiosInstanceContext.Provider
      value={{
        touringApiService,
        updateApiToken,
        token,
        CancelToken: axios.CancelToken,
      }}
    >
      {children}
    </AxiosInstanceContext.Provider>
  );
}

export default AxiosInstanceProvider;

export const readToken = () => {

  const token =
    localStorage['persist:touring'] &&
    JSON.parse(window.localStorage['persist:touring']).auth &&
    JSON.parse(JSON.parse(window.localStorage['persist:touring']).auth).token
      ? JSON.parse(JSON.parse(window.localStorage['persist:touring']).auth)
          .token
      : sessionStorage.getItem('temporalTouringToken')
      ? sessionStorage.getItem('temporalTouringToken')
      : '';

  return token;
};
