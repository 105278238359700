import { useState } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import useStyles from './UserCardStyles';
import StatusChip, { StatusChipProps } from 'components/statusChip/StatusChip';
import { ConfirmationDialog } from 'components/TouringDialogs';
import UserManagementForm from 'components/usersManagement/UserManagementForm/UserManagementForm';
import UserCardButton from 'components/usersManagement/UserCardButton/UserCardButton';
import { UserModel, EnumStatus } from 'models';
import { SelectedUser } from 'utils/selectors/usersManagement';

interface Props {
  userData?: UserModel;
  onUpdate?: (userData: Partial<SelectedUser> & Pick<UserModel, 'id'>) => void;
  onPasswordUpdateRequest?: (data: Pick<UserModel, 'email'>) => void;
}

export default function UserCard({
  userData,
  onUpdate,
  onPasswordUpdateRequest,
}: Props): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [isShowingDetail, setIsShowingDetail] = useState<boolean>(false);
  const [newUserData, setNewUserData] = useState<Partial<SelectedUser>>();

  const updateUser = () => {
    if (onUpdate && newUserData && userData) {
      onUpdate({ id: userData.id, ...newUserData });
    }
    setNewUserData(undefined);
  };

  const requestPasswordUpdate = () => {
    if (onPasswordUpdateRequest && userData)
      onPasswordUpdateRequest({ email: userData.email });
  };

  const statusChipProps: StatusChipProps = {
    status: EnumStatus.Custom,
    customMessage: userData?.enabled ? 'Habilitado' : 'Deshabilitado',
    customColor: userData?.enabled
      ? theme.customColors.deYork
      : theme.customColors.redRibbon,
  };

  return (
    <>
      <ConfirmationDialog
        open={!!newUserData}
        title="¿Guardar los cambios?"
        description="Al guardar los cambios se sobreescribirán los datos anteriores"
        onConfirmOptions={{
          text: 'Finalizar el alta',
          action: updateUser,
        }}
        onCancelOptions={{
          text: 'Cancelar',
          action: () => setNewUserData(undefined),
        }}
      />

      <div className={classes.card}>
        {isShowingDetail ? (
          <UserManagementForm
            canToggleEdit
            primaryBtnOptions={{
              text: 'Guardar cambios',
              callback: (newData: Partial<SelectedUser>) =>
                setNewUserData(newData),
            }}
            secondaryBtnOptions={{
              text: 'Cerrar',
              callback: () => setIsShowingDetail(false),
            }}
            requestPasswordUpdateBtnOptions={{
              callback: requestPasswordUpdate,
            }}
            initialUser={userData}
          />
        ) : (
          <Grid container justifyContent="space-between">
            <div className={classes.titleContainer}>
              <Typography variant="h3" classes={{ root: classes.userTitle }}>
                {userData?.firstName} {userData?.lastName}
              </Typography>
              <StatusChip {...statusChipProps} />
            </div>
            <UserCardButton onClick={() => setIsShowingDetail(true)}>
              Ver detalles
            </UserCardButton>
          </Grid>
        )}
      </div>
    </>
  );
}
