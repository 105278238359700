export interface RolModel {
  id?: number;
  authority: string;
  description: string;
}

export enum backOfficeRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPERVISOR = 'ROLE_SUPERVISOR',
  ROLE_OPERATOR = 'ROLE_OPERATOR',
  ROLE_DISPATCHER = 'ROLE_DISPATCHER',
}

export enum TowRoles {
  ROLE_TOW_DRIVER = 'ROLE_TOW_DRIVER',
  ROLE_TOW_OWNER = 'ROLE_TOW_OWNER',
}
