/* eslint-disable @typescript-eslint/no-explicit-any */
import Autocomplete, { AutocompleteProps } from '@material-ui/core/Autocomplete';
import { TextFieldProps } from '@material-ui/core/TextField';
import TouringTextField from 'components/TouringTextField';

type AutocompleteType = Omit<AutocompleteProps<any, undefined, undefined, undefined>, 'renderInput'>;

interface Props extends AutocompleteType {
  onChange: ((event: React.SyntheticEvent<Element, Event>, value: any) => void) | undefined;
  textFieldProps?: TextFieldProps;
}

export default function TouringAutocomplete({
  onChange,
  value,
  options,
  getOptionSelected,
  getOptionLabel,
  disabled,
  textFieldProps,
}: Props): JSX.Element {
  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={onChange}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      renderInput={(params) => <TouringTextField {...params} {...textFieldProps} key={params.id} />}
      style={{ minWidth: 150 }}
    />
  );
}
