import { TowBaseModel, VehicleServiceModel } from 'models';
import { ServiceAssignmentAction } from 'redux/actions/serviceAssignment';

interface SelectedTowBaseState {
  value?: TowBaseModel;
  serviceVehicleId?: number;
  delay?: number;
  serviceVehicles: VehicleServiceModel[];
  loading: boolean;
  error?: string;
}

const initialState: SelectedTowBaseState = {
  value: undefined,
  serviceVehicleId: undefined,
  delay: undefined,
  serviceVehicles: [],
  loading: false,
};

function selectedTowBaseReducer(
  state: SelectedTowBaseState = initialState,
  action: ServiceAssignmentAction
): SelectedTowBaseState {
  switch (action.type) {
    case 'LOADING_SELECTED_TOW_BASE': {
      return {
        ...state,
        value: undefined,
        serviceVehicles: [],
        loading: true,
      };
    }
    case 'SET_SELECTED_TOW_BASE': {
      return {
        ...state,
        value: action.payload?.towBase,
        delay: action.payload?.delay,
        serviceVehicleId: action.payload?.serviceVehicleId,
        serviceVehicles: action.payload?.serviceVehicles || [],
        loading: false,
      };
    }
    case 'SELECTED_TOW_BASE_ERROR': {
      return {
        ...state,
        value: undefined,
        serviceVehicles: [],
        loading: false,
        error: action.payload,
      };
    }
    case 'REMOVE_SELECTED_TOW_BASE': {
      return {
        ...state,
        value: undefined,
        serviceVehicles: [],
        loading: false,
        error: undefined,
      };
    }
    default: {
      return state;
    }
  }
}

export type { SelectedTowBaseState };
export { selectedTowBaseReducer };

