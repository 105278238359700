import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { currentServiceReducer, CurrentServiceState } from './currentService';
import { towBasesByServiceTypeReducer, TowBasesByServiceTypeState } from './fetchTowBasesByServiceType';
import {
  selectedTowBaseReducer,
  SelectedTowBaseState,
} from './selectedTowBase';
import {
  suggestedTowBaseReducer,
  SuggestedTowBasesState,
} from './suggestedTowBases';
import {
  towServiceRateModalReducer,
  TowServiceRateModalState,
} from './towServiceRateModal';

interface ServiceAssignmentState {
  currentService: CurrentServiceState;
  selectedTowBase: SelectedTowBaseState;
  suggestedTowBases: SuggestedTowBasesState;
  towServiceRateModal: TowServiceRateModalState;
  towBasesByServiceType: TowBasesByServiceTypeState;
}

const serviceAssignmentReducer = persistCombineReducers(
  {
    key: 'serviceAssignment',
    storage: storage,
    blacklist: ['selectedTowBase', 'towServiceRateModal, towBasesByServiceType'],
  },
  {
    currentService: currentServiceReducer,
    selectedTowBase: selectedTowBaseReducer,
    suggestedTowBases: suggestedTowBaseReducer,
    towServiceRateModal: towServiceRateModalReducer,
    towBasesByServiceType: towBasesByServiceTypeReducer
  }
);

export type { ServiceAssignmentState };
export default serviceAssignmentReducer;
