import { makeStyles, Theme } from '@material-ui/core';
import { NAVBAR_HEIGHT } from 'styles/theme';

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    boxSizing: 'border-box',
    padding: theme.spacing(5),
    width: '100%',
    display: 'block',
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    mixBlendMode: 'normal',
    overflow: 'auto',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(174, 198, 214, 0.41) 100%), #FAFAFA',
  },
  greyserContainer: {
    backgroundColor: theme.customColors.geyser,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(4),
  },
  greyserContainerContent: {
    backgroundColor: theme.customColors.white,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2.5),
    height: '100%',
  },
  notFoundContainer: {
    padding: theme.spacing(2.625),
  },
  notFoundText: {
    color: theme.customColors.biscay,
    fontWeight: 700,
    textAlign: 'center',
  },
}));

export default useStyles;
