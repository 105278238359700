import { makeStyles, Theme } from '@material-ui/core';
import { hexToRgbA } from 'utils/helperFunctions';

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.01, 1),

    '&:disabled svg > path': {
      stroke: theme.customColors.grayForIcons,
      fill: theme.customColors.grayForIcons,
    },
  },
  isEditing: {
    '&  svg > path': {
      stroke: theme.customColors.buttonColor,
      fill: theme.customColors.white,
    },
  },
  editBtn: {
    padding: theme.spacing(0, 1.25, 0, 1),
  },
  deleteBtn: {
    borderColor: theme.customColors.redRibbon,
    color: theme.customColors.redRibbon,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0, 1.25, 0, 0.625),

    '&:hover': {
      borderColor: theme.customColors.redRibbon,
      color: theme.customColors.redRibbon,
      backgroundColor: hexToRgbA(theme.customColors.redRibbon, 0.02),
    },
  },
  changePasswordBtn: {
    color: theme.customColors.astral,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700,
  },
}));

export default useStyles;
